import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter } from 'react-router-dom'
import reducer from './store/reducer';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './index.css';

// Import the functions you need from the SDKs
//Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//Redux
import { Provider } from 'react-redux';
import { createStore } from 'redux';

//Firebase JS SDK configuration settings
const firebaseConfig = {
  apiKey: "AIzaSyAF9HxLoX3FGuz9RHSWya40tg8kpCO0tys",
  authDomain: "uplabs-c7df9.firebaseapp.com",
  projectId: "uplabs-c7df9",
  storageBucket: "uplabs-c7df9.appspot.com",
  messagingSenderId: "805320410623",
  appId: "1:805320410623:web:9db6836f4742c4b30a13ca",
  measurementId: "G-YL3417STE6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

//Initialize Redux parameter
const store = createStore(reducer);
 
//Render application  

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App /> 
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
