import React, { useState, useEffect } from "react";
import classes from "./Gallery.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import portfolio from './external';
import "../slider.css";
import { Link, useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";

const GalleryProjects = (props) => {

    const navigate = useNavigate()
    const [portfolio, setPortfolio] = useState([]);
  
    useEffect( () => {

        (async () => {
        const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=2&_embed&per_page=20');
        const data = await response.json();
        const portfoliodata = data.map((datas) => {
           let portfolioimg  =   datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.['source_url'];
           let prtags =   datas?.['_embedded']?.['wp:term']?.[1].map((el) =>{return el.name});
           let portWidth = datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.width;
           let portHeight = datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.height; 
           let emer = datas?.title?.rendered;
          if(!portfolioimg){
            portfolioimg = 'https://1up.al/static/media/1upLogo.09249cddff1b13556310.png';
          }
          
       
          return {
            src: portfolioimg,
            tags: prtags,
            slug : datas.slug,
            w : portWidth,
            h : portHeight,
            emer : emer
          }
        });
        setPortfolio(portfoliodata);
      })();
      
      },[]);
      const [items, setItems] = useState(portfolio);
     

      console.log("portofolio" ,portfolio);
      console.log(portfolio.map(i =>{ return i.emer}));
      console.log(portfolio.map(i =>{ return {a: i.h, b: i.w}}));
      
    const filterItem = (categItem) =>{
        const updatedItems = portfolio.filter((curElem) => {
            return curElem.tags.map(el => el)?.includes(categItem);
        });
        setItems(updatedItems);
    };
    

    console.log("itemat" ,items);
    console.log("ez",items.map(el => {return el.tags.map(el => el)}));
    const resultsRender = [];
    const half = Math.ceil(items.length / 2); 
    const first = items.sort((a,b) => (a.h > b.h) ? 1 : (a.h < b.h) ? -1 :0).slice(0,half);
    const sec = items.slice(half);
    let c = [];
    for(let j = sec.length-1; j >= 0; j--){
        c.push(sec[j]);
    }
      let d = [];
      for(let i = 0; i < first.length + c.length;i ++){
        if(i % 2 === 0){
          d.push(first[i/2]); 
          }
        else{
          d.push(c[(i-1)/2]);
          }

      }

      function swap (d) {
      for(let i = 1; i < d.length; i+=3){
        let j = i + 1;
    
        if( i % 2 === 0){
            let tmp = d[i];
            d[i] = d[j];
            d[j] = tmp;
          }
      console.log(i)
    }
    }
    
    swap(d);
    
    console.log("d",d);
    console.log("c",c);
    console.log("first",first);
    console.log("sec",sec);
  
       for(let i = 0; i < d.length; i += 2){
            resultsRender.push(
                <div className={classes.col4}>
                    {
                    d.slice(i,i + 2)
                        .map((item,index) => (<> 
                            {/* <Link to='/' className={classes.images}> */}
                            <div className={classes.images} onClick={()=>{navigate("/")}}>
                             <img key={item}  src= {item.src} style ={{ maxHeight : item.h/2 , width : item.w / 2}}/>  
                            </div>
                             {/* </Link> */}
                            </>
                        ))
                    }
                </div>
            );
            }
          
    
    
    // else{
    //     for (let i = 0; i < items.length; i += 1) {
    //         resultsRender.push(
    //             <div className={classes.col2}>
    //                 {
    //                 items.slice(i, i + 1)
    //                     .map(item => (                       
    //                     <img  key={item}  src= {item.src}  style = {{height : item.h / 2 , width : item.w / 2}}/>                            
    //                     ))
    //                 }
    //             </div>
    //         );

    //     }
    // }
    const settings = {
        infinite: false,
		dots: false,
		arrows: false,
		slidesToShow: 4,
		slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 4,
                  centerMode: false,
                  slidesToScroll: 1,
                  infinite: false
                }
              },
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				centerMode: false,
				slidesToScroll: 1,
				infinite: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: false
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: false
			  }
			}
		]
	};
    useEffect(() => {
      
        setItems(portfolio);
    },[portfolio])

    return (
        <>
            <div className={classes.content}>
                <div className='row'>
                    <div className={classes.header}>
                        <h2>Our Works</h2>
                        <div className={classes.tab}>
                            <button className={classes.button} onClick = {() => {setItems(portfolio)}}>All</button>
                            <button className={classes.button} onClick = {() => filterItem('Website')}>Website</button>
                            <button className={classes.button} onClick = {() => filterItem('Mobile Application')}>Mobile Application</button>
                            <button className={classes.button} onClick = {() => filterItem('Marketing')}>Marketing</button>
                            <button className={classes.button} onClick = {() => filterItem('Platform')}>Platform</button>
                        </div>
                    </div>
                </div>
                <MediaQuery minWidth={769}>
                <div className={classes.customrow}>
                <div className='projectsPortofolio'>
                <Slider {...settings}>
                    {resultsRender}
                </Slider>
                </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth={768}>
                <div className={classes.customrow}>
                <div className={classes.projectport}>
                    
                    {resultsRender}
                
                </div>
                </div>
                </MediaQuery>
            </div>
        </>
    )
}

export default GalleryProjects;
