import React, { useCallback, useEffect, useState, lazy, Suspense } from "react";
import GoogleMapReact from "google-map-react";

import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

import Slider from "react-slick";
import "./slide.css";
import "./Marker.css";

import Footer from "../../components/Footer";
import MediaQuery from "react-responsive";
import SocialBtns from "../../components/SocialBtns";
import classes from "./ContactSerious.module.css";

import facebook from "../../assets/animations/facebook.svg";
import instagram from "../../assets/animations/instagram.svg";
import linkedin from "../../assets/animations/linkedin.svg";
import logo from "../../assets/icons/1UPLogo-bardhe.png";
import getDirection from "../../assets/contact/traffic-sign.png";
import { Helmet } from "react-helmet";
const axios = require("axios").default;

const ContactSerious = (props) => {
  const [isShown, setIsShown] = useState(false);
  const [inputs, setInputs] = useState({});
  const [message_status, setMessage_status] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");

  // const registerUser = useCallback(async () => {
  //   console.log("InsideRegisterUser");

  //   if (!executeRecaptcha) {
  //     return;
  //   }
  //   const result = await executeRecaptcha("register");
  //   console.log("This is resutl:", result);
  //   setToken(result);
  // }, [executeRecaptcha]);

  // useEffect(() => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }
  //   const handleReCaptchaVerify = async () => {
  //     const token = await executeRecaptcha("register");
  //     setToken(token);
  //   };
  //   handleReCaptchaVerify();
  // }, [executeRecaptcha]);

  let handleSubmitForm = (e) => {
    e.preventDefault();

    let url =
      "https://cms.1up.al/wp-json/contact-form-7/v1/contact-forms/240/feedback";

    let formData = new FormData();
    formData.append("upname", inputs.name);
    formData.append("upcompany", inputs.company);
    formData.append("upmail", inputs.email);
    formData.append("upphone", inputs.phone);
    formData.append("upmessage", inputs.message);
    // formData.append("_wpcf7_recaptcha_response", token);
    formData.append("_wpcf7_unit_tag", "df1d7b1");

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    setInputs("");

    axios
      .post(url, formData, config)
      .then((response) => {
        setMessage_status(response.data.message);
        setInputs("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const cordinates = {
    lat: 41.3207852,
    lng: 19.8231162,
  };
  const settings = {
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
  };
  const center = { lat: 41.321023135720964, lng: 19.825622877684875 };
  const handlePin = (event) => {
    setIsShown((current) => !current);
  };
  const Footer = lazy(() => import("../../components/Footer"));
  const renderLoader = () => <p>Loading...</p>;

  return (
    <>
      <Helmet>
        <title>Contact Serious</title>
        <meta name="description" content="Contact Serious" />
      </Helmet>
      <div className={classes.contactContainer}>
        {/* <img src={require('../../assets/contact/new_bck.png')} alt="background" className={classes.backgroundImg} /> */}
        <div className={classes.sliderContainer}>
          <Slider {...settings}>
            <img src={require("../../assets/contact/img1.png")} alt="Contact" />

            <img src={require("../../assets/contact/img2.png")} alt="Contact" />
            <img src={require("../../assets/contact/img3.png")} alt="Contact" />
          </Slider>
          <MediaQuery minWidth={900}>
            <SocialBtns
              dark={true}
              light={true}
              className={classes.socialButtons}
            />
          </MediaQuery>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.formContainerContent}>
            <div className={classes.formGreeting}>
              <img
                src={require("../../assets/contact/hand.png")}
                className={classes.greeatingImg}
                alt="greeating"
              />
              <h4>Get in touch</h4>
              <div className={classes.infoContact}>
                <div className={classes.msgCelContact}>
                  <div className={classes.infoContactDay}>
                    <p className={classes.text}>
                      <img
                        src={require("../../assets/contact/clock.svg").default}
                        className={classes.msgImage}
                        alt="clock"
                      />
                      Monday – Friday(9 am to 6 pm)
                    </p>
                  </div>
                  <div className={classes.infoContactCel}>
                    <p className={classes.text}>
                      <img
                        src={require("../../assets/contact/mail.svg").default}
                        className={classes.msgImage}
                        alt="mail"
                      />
                      info@1up.al
                    </p>
                  </div>
                </div>

                <div className={classes.verticalLine}></div>
                <div className={classes.infoContactLocation}>
                  <p className={classes.title}>Location</p>
                  <p className={classes.text}>
                    Rr.Themistokli Gërmenji Nr.1 Tiranë 1001, Albania
                  </p>
                </div>
              </div>
            </div>

            {/* <GoogleReCaptchaProvider
              reCaptchaKey={"6LcffKApAAAAAGhWCU7eMfRazxTYpXQGVtInG6-0"}
            > */}
            <div className={classes.contactFormContent}>
              <img
                src={require("../../assets/contact/hand.png")}
                className={classes.greeatingImgMobile}
                alt="greeating"
              />
              <h4>Say hello</h4>

              <form onSubmit={handleSubmitForm}>
                <div className={classes.formGroup}>
                  <div>
                    <p>Name</p>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      className={classes.input}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div>
                    <p>Company</p>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={inputs.company || ""}
                      onChange={handleChange}
                      className={classes.input}
                      placeholder="Company"
                      required
                    />
                  </div>
                </div>

                <div className={classes.formGroup}>
                  <div>
                    <p>Email</p>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                      className={classes.input}
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div>
                    <p>Phone</p>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={inputs.phone || ""}
                      onChange={handleChange}
                      className={classes.input}
                      placeholder="Phone"
                      required
                    />
                  </div>
                </div>

                <div className={classes.formGroupSubmit}>
                  <div className={classes.formGroupMsg}>
                    <p>Message</p>
                    <textarea
                      value={inputs.message || ""}
                      onChange={handleChange}
                      name="message"
                      maxLength={100}
                      className={classes.textarea}
                      cols={6}
                      rows={4}
                      placeholder="Your message"
                      required
                    />
                  </div>

                  <div
                    className={classes.submitContainer}
                    // onClick={registerUser}
                  >
                    <input
                      type="submit"
                      value="Submit"
                      className={classes.submit}
                    />
                    <img
                      src={require("../../assets/contact/send.svg").default}
                      alt="white-right-icon"
                      className={classes.submitIcon}
                    />
                  </div>
                </div>
                <div className="message_status">
                  {message_status ? <p>{message_status}</p> : null}
                </div>
              </form>
            </div>
            {/* </GoogleReCaptchaProvider> */}

            <div className={classes.careers}>
              <div className={classes.careersText}>
                <h5>CAREERS</h5>
                <h6>Join our team</h6>
                <p>
                  One third of your life is spent at work. Choose to work
                  somewhere that invests in you too.
                </p>
                <p>
                  We are looking for talented people who want to win at life and
                  business.
                </p>
              </div>
              <div className={classes.careersBtn}>
                <button className={classes.contactButton}>
                  <a href="mailto:info@1up.al">See open positions</a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.mapContainer}>
          <div className={classes.map}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
              defaultCenter={center}
              defaultZoom={17}
            >
              <img
                lat={center.lat}
                lng={center.lng}
                src={require("../../assets/contact/pin.svg").default}
                style={{
                  width: 20,
                  height: "auto",
                  objectFit: "contain",
                  position: "relative",
                  top: "-64.128px",
                  cursor: "pointer",
                }}
                alt="map-marker"
                className={classes.pin}
                onClick={handlePin}
              />

              {isShown && (
                <div
                  className={classes.speechbubble}
                  lat={center.lat}
                  lng={center.lng}
                >
                  <img src={logo} className={classes.logo1up} />
                  <p className={classes.desc1up}> 1 UP LABS</p>
                  <div className={classes.direction}>
                    <a
                      href="https://www.google.com/maps/dir//41.3210392,19.8255591/@41.3209849,19.8255241,20z"
                      style={{ display: "contents" }}
                    >
                      <img
                        src={getDirection}
                        className={classes.getDirection}
                      />
                    </a>
                  </div>
                </div>
              )}
            </GoogleMapReact>
          </div>

          <div className={classes.formGreetingMobile}>
            <div className={classes.infoContactLocation}>
              <p className={classes.title}>Location</p>
              <p className={classes.text}>
                Rr.Themistokli Gërmenji Nr.1 Tiranë 1001, Albania
              </p>
            </div>
            <h4>Get in touch</h4>
            <div className={classes.infoContact}>
              <div className={classes.infoContactDay}>
                <p className={classes.text}>
                  <img
                    src={require("../../assets/contact/clock.svg").default}
                    className={classes.msgImage}
                    alt="clock"
                  />
                  Monday – Friday(9 am to 6 pm)
                </p>
              </div>
              <div className={classes.infoContactCel}>
                <p className={classes.text}>
                  <img
                    src={require("../../assets/contact/mail.svg").default}
                    className={classes.msgImage}
                    alt="mail"
                  />
                  <a href="mailto:info@1up.al">info@1up.al</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <MediaQuery maxWidth={900}>
          <div
            style={{ padding: "20px 0 20px 26px" }}
            className={classes.socialButtonsMobile}
          >
            <div className={classes.socialIcons}>
              <img src={instagram} />
            </div>
            <div className={classes.socialIcons}>
              <img src={facebook} />
            </div>
            <div className={classes.socialIcons}>
              <img src={linkedin} />
            </div>
          </div>
          <Suspense fallback={renderLoader()}>
            <Footer />
          </Suspense>
        </MediaQuery>
      </div>
    </>
  );
};

export default ContactSerious;
