import React, { useState, useEffect } from 'react';
import { useNavigate  } from "react-router-dom";

import Header from "../../components/Header";

import classes from './CrazyProject.module.css'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LastPage =()=>{
    const navigate = useNavigate()

    const [portfolio, setPortfolio] = useState([]);
  
    useEffect( () => {
        (async () => {
        const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=2&_embed&per_page=20');
        const data = await response.json();
        const portfoliodata = data.map((datas) => {
          let portfolioimg  =   datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.['source_url'];
          return {
            src: portfolioimg,
            // tags: prtags,
            slug : datas.slug
          }
        });
          setPortfolio(portfoliodata);
    })();
    },[]);

    const settings = {
		infinite: true,
		dots: false,
		arrows: false,
		slidesToShow: 8,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 5000,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true
			  }
			}
		]
	};

    return(
        <>
            {/* <Header/> */}

            <div className={classes.lastPage_container} style={{fontFamily:"SofiaPro-Light"}}>
                <div className={classes.short_desc}>
                    <p style={{fontSize:'28px'}}>Want to see more ?</p>
                    <p style={{color:"#6E6E6E", fontSize:'14px', textAlign:"center", border:"solid 1px #2B2B2B" , paddingTop:"15px", paddingBottom:"15px", borderRadius:"4px"}}>Discover all our projects</p>
                </div>
                <div className={classes.projectsContainer}>
                    <div  className="projectsContainerslides">
                        <Slider {...settings}>
                            {portfolio.map(el => {
                            return (
                                <img
                                    src={el.src}
                                    className={classes.projectLogo}
                                    alt="projectPhotos"
                                    onClick={()=>{navigate(`/portfolio/project/${el.slug}`)}}
                                />
                            )
                            })}
                        </Slider>
                    </div>
                </div>
                <div className={classes.share_buttons} style={{paddingTop:"130px"}}>
                    <p classname={classes.sharetext} style={{textAlign:"center", color:"#6E6E6E", fontSize:"11px"}}>Share it</p>
                    <div className={classes.socialButtona}>
                        <button>Facebook</button>
                        <button>Twitter</button>
                        <button>Pin it</button>
                    </div>
                </div>
                <div className={classes.lastSquare}>
                    <p>THANK YOU</p>
                </div>
            </div>
        </> 
    )
 
}

export default LastPage;