import React,{useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import classes from './Porsche.module.css';
import MediaQuery from 'react-responsive'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../../components/slider.css'


import Footer from '../../../components/Footer'


import Mobile from '../../../assets/porsche/mobile.png';


const Porsche = props =>{

    const [post, setPost] = useState([]);
    const { slug } = useParams();
    useEffect( () => {
      let isMounted = true;      
      (async () => {
        const response = await fetch(`https://cms.1up.al/wp-json/wp/v2/posts?categories=2&_embed&slug=${slug}`);
        console.log(response);
        const data = await response.json();
        const ok = data.map((datas) =>{
        // let datat = new Date(datas.date).toLocaleDateString('en-GB', { day:"numeric", month:"long"});
          let desc =  datas?.['acf']?.['emri_partnerit'];
          let src = datas?.['acf']?.['logo_partnerit']?.['sizes']?.['thumbnail'];
          let descr = datas?.['acf']?.['pershkrimi'];
          let specs = datas?.['acf']?.['specifikimet'];
          let techicon = datas?.['acf']?.['teknologjite'];
          let sideimg=datas?.['acf']?.['imazhi_kryesor_brenda_projektit']?.['sizes']?.['large'];

        //   let specsArr = [];
        //   if(specs){specsArr = specs.split(",");}
         
        //   console.log(specsArr);  
            
          return{
            title   :   desc,
            src     :   src,
            id      :   datas.id,
            descr   :   descr,
            specs   :   specs,
            tech    :   techicon,
            sideimg :   sideimg
          }
          
        });
           if(isMounted) setPost(ok);
      })(); 
      return () => { isMounted = false }; 

   },[]);
   console.log(post)

   let icons={
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    // centerMode:true,
    }
    return(
        <>
        {post.map((el) =>
            <div   key = {el.id} className={classes.container}>
                <div className={classes.main}>
                    <div className={classes.logo}>
                        <img src={el.src}></img>
                    </div>

                    <div className={classes.companyName}>
                        <div className={classes.verticalLine_1}></div>
                        <p className={classes.comapnyTitle} style={{fontFamily:'Montserrat,sans-serif'}}>{el.title}</p>
                        <div className={classes.verticalLine_2}></div>
                        {/* <p className={classes.comapnyDate} style={{fontFamily:'SofiaPro-Medium'}}>20 AUG 2021</p> */}
                    </div>
                    <MediaQuery maxWidth={900}>
                        <div className={classes.mobile_Photo}>
                            <img src={Mobile}></img>
                        </div>
                    </MediaQuery>
                    <div className={classes.miniContainer}>
                        <div className={classes.companyInfo}>
                            <p className={classes.title} style={{fontFamily:'SofiaPro-Medium'}}>Our Solution</p>
                            <p className={classes.desc} style={{fontFamily:'Montserrat,sans-serif'}}>
                                {el.descr}
                            </p>
                        </div>

                        <div className={classes.icons}>
                            <div className={classes.organize}>
                                {el.specs.map((data) =>{ return <p  className={classes.styling} style={{fontFamily:'Montserrat,sans-serif'}}><img src={data.ikona_specifikimit.sizes.thumbnail} className={classes.ikonatSpecifikim}/> {data.titulli_specifikimit} </p>})}
                            </div>
                        </div>
                        
                        <div className={classes.technologyIcons}>
                            {el.tech.map((data) =>{ return <img src={data.ikona_tekonologjise.sizes.thumbnail} className={classes.ikonat_tek}/> })}
                        </div>

                        <div className="icons_mobile">
                            <MediaQuery maxWidth={900}>
                                <Slider {...icons}>
                                    {el.tech.map((data) => {return <img src={data.ikona_tekonologjise.sizes.thumbnail} className={classes.ikonat_tek}/>})}
                                </Slider>
                            </MediaQuery>
                        </div>
                        
                        
                    </div>

                </div>
                <div className={classes.side}>
                    <div className={classes.mobilePhoto}>
                        <img src={el.sideimg}></img>
                    </div>
                    <div className={classes.circle}>
                        <div className={classes.outerCircle}> 
                            <div className={classes.innerCircle}>
                                <div className={classes.smallCircle}></div>
                                <span style={{fontFamily:'SofiaPro-Bold'}} className={classes.first}>GO CRAZY</span>
                                <span style={{fontFamily:'SofiaPro-Bold'}} className={classes.testing}>GO CRAZY</span>
                            </div>
                        </div>
                    </div>
                </div>
                <MediaQuery maxWidth={900}>
                    <div className={classes.lastSide}>
                        <div className={classes.thecircle}>
                            <div className={classes.innerCircle}>
                                <div className={classes.smallCircle}></div>
                                <span style={{fontFamily:'SofiaPro-Bold'}} className={classes.first}>GO CRAZY</span>
                                <span style={{fontFamily:'SofiaPro-Bold'}} className={classes.testing}>GO CRAZY</span>
                            </div>
                        </div>

                        <div className={classes.square}>
                    </div>
                    
                        </div>
                        <div style={{marginTop:'-18%'}}>
                            <Footer/>
                        </div>
                </MediaQuery>
            </div>
    )}
    </>
    )
}
export default Porsche;
