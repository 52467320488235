import React ,{ useState} from "react";

import classes from './CrazyProject.module.css'

const FifthPage =()=>{
    const [isActive, setIsActive] = useState(true);
    const [isClicked, setIsClicked] = useState(false);

    // const DyqaniImg=[
    //     {id: 1, src: '../../assets/'}
    // ]

    const handleClick =()=>{
        setIsActive(current => !current);
        setIsClicked(false)
    }
    const handleClickLeft =()=>{
        setIsClicked(current => !current);
        setIsActive(false)
    }
    
    return(
        <>
            <div className={classes.fifthcontainer}>
                    <div className={classes.buttons_dyqan_service} style={{fontFamily:"SofiaPro-Light"}}>
                        <p style={{fontFamily:"SofiaPro-Light", color: isActive ? "#58C2D7" : "#000000"}} onClick={handleClick}>DYQANI</p>
                        <p className={classes.line}></p>
                        <p style={{fontFamily:"SofiaPro-Light", color: isClicked ? "#58C2D7" : "#000000"}} onClick={handleClickLeft}>SERVISI</p>
                    </div>
                    <div className={classes.desc1} style={{fontFamily:"SofiaPro-Light"}}>
                        <p className={classes.whiteBigText} style={{fontFamily:"SofiaPro-Bold", width:"50%"}}> Self-Custody Crypto Wallet for DFT, NFT and Metaverse </p>
                    </div>
                    <div>
                        { isActive && <img src={require("../../assets/porsche/crazy/04@2x.png")}></img> }
                    </div>
                    <div>
                        { isClicked && <img src={require("../../assets/porsche/crazy/04@2x.png")}></img> }
                    </div>
            </div>
        </> 
    )
 
}

export default FifthPage;