import React ,{ useEffect, useState }from "react";
import classes from './GeneralInfo.module.css'
import { useParams } from 'react-router-dom';
import Footer from "./Footer";
import MediaQuery from 'react-responsive'

    const GeneralInfo=(props)=>{
    const { slug } = useParams();

    const [info, setInfo]=useState([])
    useEffect(()=>{
        let isMounted = true;      

        (async()=> {
            const response= await fetch(`https://cms.1up.al/wp-json/wp/v2/posts?categories=19&_embed&slug=${slug}`);
            const data=await response.json();
            const generalinformation=data.map((datas)=>{
                return{
                    title : datas.title.rendered,
                    content: datas.content.rendered,
                }
            });
            if(isMounted)setInfo(generalinformation)
        })();

        return () => { isMounted = false }; 
    },[]);

    return(
        <div>
            <div className={classes.container}>
                {info.map(el=>{
                    return(
                        <div className={classes.miniContainer}>
                            <h2 style={{fontFamily:'SofiaPro-Medium'}} className={classes.title} dangerouslySetInnerHTML={{ __html: el.title}}></h2>
                            <p  style={{fontFamily:'Montserrat,sans-serif'}}  className={classes.content}  dangerouslySetInnerHTML={{ __html: el.content}} ></p>
                        </div>
                    )
                })}
            </div>
            <MediaQuery minWidth={900}>
                <div style={{paddingLeft:'380px', paddingRight:'380px', paddingTop:'20px'}}>
                    <Footer/>
                </div>
            </MediaQuery>
            
            <MediaQuery maxWidth={900}>
                <div>
                    <Footer/>
                </div>
            </MediaQuery>
           
        </div>
        
    )
    }
export default GeneralInfo;