import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import formClasses from "./JoinOurTeam.module.css";
import classes from "../../components/Header.module.css";

export const JoinOurTeam = () => {
  const [message_status, setMessage_status] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [userCV, setUserCV] = useState(undefined);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("full-name", fullName);
      formData.append("your-email", email);
      formData.append("your-cv", userCV);
      formData.append("_wpcf7_unit_tag", "72b942c");
      // formData.append("_wpcf7_recaptcha_response", token);

      const response = await axios.post(
        "https://cms.1up.al/wp-json/contact-form-7/v1/contact-forms/848/feedback",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${tokenParsed}`,
          },
        }
      );

      console.log(response);
      setMessage_status(response.data.message);
      setEmail("");
      setFullName("");
      setUserCV(undefined);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        zIndex: 99999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          fontSize: "20px",
          color: "white",
          bottom: "50px",
          cursor: "pointer",
        }}
        onClick={() => {
          //   setJoinModal(!joinModal);
          navigate("/");
        }}
      >
        {/* <img
          src={require("../../assets/icons/backIconTest.svg").default}
          className={classes.leftArrowIcon}
          alt="left-arrow"
        /> */}
        <p className={classes.backText} style={{ color: "white" }}>
          BACK TO HOME
        </p>
      </div>

      <div style={{ width: "100%", maxWidth: "700px" }}>
        <div
          className={formClasses.formContainerContent}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={formClasses.formGreeting}>
            <h4>Get in touch</h4>
            <div className={formClasses.infoContact}>
              <div className={formClasses.msgCelContact}>
                <div className={formClasses.infoContactDay}>
                  <p className={formClasses.text}>
                    <img
                      src={require("../../assets/contact/clock.svg").default}
                      className={formClasses.msgImage}
                      alt="clock"
                    />
                    Monday – Friday(9 am to 6 pm)
                  </p>
                </div>
                <div className={formClasses.infoContactCel}>
                  <p className={formClasses.text}>
                    <img
                      src={require("../../assets/contact/mail.svg").default}
                      className={formClasses.msgImage}
                      alt="mail"
                    />
                    info@1up.al
                  </p>
                </div>
              </div>

              <div className={formClasses.verticalLine}></div>
              <div className={formClasses.infoContactLocation}>
                <p className={formClasses.title}>Location</p>
                <p className={formClasses.text}>
                  Rr.Themistokli Gërmenji Nr.1 Tiranë 1001, Albania
                </p>
              </div>
            </div>
          </div>

          {/* <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_SITE_KEY}
              > */}
          <div className={formClasses.contactFormContent}>
            <h4>tell us more about yourself</h4>

            <form
              onSubmit={handleSubmit}
              style={{
                width: "100%",
              }}
            >
              <div style={{ width: "93%" }}>
                <p>Name</p>
                <input
                  type="text"
                  id="fullname"
                  name="fullname"
                  value={fullName}
                  onChange={(event) => {
                    setFullName(event.target.value);
                  }}
                  className={formClasses.input2page}
                  placeholder="Full Name"
                  required
                />
              </div>

              <div style={{ width: "93%" }}>
                <p>Email</p>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  className={formClasses.input2page}
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* <div className={formClasses.formGroupSubmit}> */}

              <p>Upload your CV</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <input
                  encType="multipart/form-data"
                  type="file"
                  style={{ height: "67px", width: "70%" }}
                  onChange={(event) => setUserCV(event.target.files[0])}
                  name="cv"
                  placeholder="Your CV"
                  required
                />

                <button
                  // disabled={true}
                  type="submit"
                  style={{
                    backgroundColor: "transparent",
                    outline: "none",
                    borderRadius: "2px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "rgba(225, 225, 225, 0.2)",
                    height: "100px",
                    width: "20%",
                  }}
                >
                  <img
                    src={require("../../assets/contact/send.svg").default}
                    alt="white-right-icon"
                    className={formClasses.submitIcon}
                  />
                </button>
              </div>

              {/* </div> */}
              <div className="message_status">
                {message_status ? <p>{message_status}</p> : null}
              </div>
            </form>
          </div>
          {/* </GoogleReCaptchaProvider> */}
        </div>
      </div>
    </div>
  );
};
