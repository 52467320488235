import React from 'react';
import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords, image, type, url }) => {
  return (
    <Helmet>
		<title>{title}</title>
		<meta name='description' content={description} />
		<meta name='keyword' content={keywords} />

		<meta property="og:url" content={url} />
		<meta property="og:type" content={type} />
		<meta property="og:title" content={title} />
		<meta property="og:description" content={description} />
		<meta property="og:image" content={image} />

		<meta name="twitter:site" content={title}/>
		<meta name="twitter:creator" content={title} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: '1UP Labs',
  description: '1Up Labs official website',
  keywords: '1up, website, design',
  image: '/static/media/1upLogo.09249cdd.png',
  type: 'page',
  url: 'www.1up.al'
}

export default Meta;