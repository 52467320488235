import React, { useEffect, useRef, useState, lazy, Suspense } from 'react'
import * as THREE from 'three'
import GoogleMapReact from 'google-map-react'
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import MediaQuery from 'react-responsive'


import Text from '../../components/Text'
import Footer from '../../components/Footer'

import earth from '../../assets/portfolio/globe.jpg'

import classes from './ContactCrazy.module.css'

import Lottie from 'react-lottie'
import animationData from '../../assets/animations/clock.json'
import animationArrow1 from '../../assets/animations/mail.json'

import facebook from '../../assets/animations/facebook.svg'
import instagram from '../../assets/animations/instagram.svg'
import linkedin from '../../assets/animations/linkedin.svg'
import logo from '../../assets/icons/1UPLogo-bardhe.png'
import getDirection from '../../assets/contact/traffic-sign.png'
import { Helmet } from 'react-helmet';

const ContactCrazy = props => {

  const [inputs, setInputs] = useState({})

  const sphereRef = useRef()

  const textStyle = {
    lineHeight: 0.3,
    fontSize: 14,
    opacity: 1,
  }

  const center = {
    lat: 41.321023135720964,
    lng: 19.825622877684875
  }

  const rotate ={
    transform: 'rotate(90deg)'
  }
  useEffect(() => {

    const ref = sphereRef.current

    let width = ref.clientWidth
    let height = ref.clientHeight

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(90, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true })

    function sphereVertex() {
      return `
        varying vec2 vertexUV;
        varying vec3 vertexNormal;
        void main(){
          vertexUV = uv;
          vertexNormal = normalize(normalMatrix * normal);
          gl_Position = projectionMatrix*modelViewMatrix*vec4(position,1.0);
        }
      `
    }

    function sphereFragment() {
      return `
        uniform sampler2D globeTexture;
        varying vec2 vertexUV;
        varying vec3 vertexNormal;
        void main(){
          gl_FragColor = vec4(texture2D(globeTexture, vertexUV).xyz, 1.0);
        }
      `
    }


    const sphere = new THREE.Mesh(new THREE.SphereGeometry(5, 50, 50), new THREE.ShaderMaterial(
      {
        vertexShader: sphereVertex(),
        fragmentShader: sphereFragment(),
        uniforms: {
          globeTexture: {
            value: new THREE.TextureLoader().load(earth)
          }
        }
      }
    ))

    camera.position.z = 8
    scene.add(sphere)

    renderer.setSize(width, height)
    renderer.setPixelRatio(window.devicePixelRatio)

    const renderScene = () => {
      renderer.render(scene, camera)
    }

    const handleResize = () => {
      width = ref.clientWidth
      height = ref.clientHeight
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderScene()
    }

    const animate = () => {
      sphere.rotation.x += 0.001
      sphere.rotation.y += 0.001

      renderScene()
      window.requestAnimationFrame(animate)
    }


    ref.appendChild(renderer.domElement)
    window.addEventListener('resize', handleResize)
    animate()


    return () => {
      window.removeEventListener('resize', handleResize)
      ref.removeChild(renderer.domElement)

      scene.remove(sphere)
    }

  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("formData", inputs)
  }

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setInputs(values => ({ ...values, [name]: value }))
  }

  const defaultSettings ={
    loop:true,
    autoplay:true,
    animationData:animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }
  const defaultSettings2 ={
    loop:true,
    autoplay:true,
    animationData:animationArrow1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }
  
  const [isShow, setIsShow] = useState(false);

  const handlePin = event => {
    setIsShow(current => !current);}
    const Footer = lazy(() => import('../../components/Footer'));
    const renderLoader = () => <p>Loading...</p>;

  return (
    
    <div className={classes.container}>
        <Helmet>
          <title>Contact Crazy</title>  
          <meta name="description" content="Contact Crazy" />  
        </Helmet>
        <MediaQuery maxWidth={900}>
            <img src={require('../../assets/contact/astronautImg.png')} alt="astronautImg" className={classes.astronautImgMobile} />
      </MediaQuery>
      {/* <div className={classes.first}> */}
      {/* <MediaQuery maxWidth={1200}>
            <img src={require('../../assets/contact/astronautImg.png')} alt="astronautImg" className={classes.astronautImgMobile} />
      </MediaQuery> */}
        <div className={classes.earthContainer}>
          <div className={classes.earth} ref={sphereRef}/>
        </div>
      {/* </div> */}
          
        <div className={classes.middleContainer}>
          <div></div>
          <div className={classes.contentContainer}>
            <div className={classes.companyInfo}>
              <Text
                title="GET IN TOUCH"
                type="main"
                style={{ color: "#58C2D7", fontSize: 22, marginTop: 0, marginBottom: 22 }}
              />
              <div className={classes.textContainer}>
                <p className={classes.text}>
                  <img src={require('../../assets/contact/clock.svg').default} 
                    className={classes.msgImage}
                    alt="clock"
                    style={{marginRight:'7px'}}
                  />
                  Monday – Friday 9 am to 6 pm
                </p>
                
                <p className={classes.text}>  
                  <img src={require('../../assets/contact/mail.svg').default}
                    className={classes.msgImage}
                    alt="mail"
                    style={{marginRight:'7px'}}
                  />
                  info@1up.al
                </p>
              </div>

              

              <div className={classes.textContainer}>
                <p className={classes.title} style={{color:'#58C2D7', fontSize:'14px'}}>LOCATION</p>
                <p className={classes.text}>Rr.Themistokli Gërmenji Nr.1</p>
                <p className={classes.text}>Tiranë 1001, Albania</p>
              </div>

              <div style={{ padding:'20px 0 20px 0px'}} className={classes.socialButtonsMobile}>
                <div className={classes.socialIcons}>                  
                  <img src={instagram}/>
                </div>
                <div className={classes.socialIcons}>
                  <img src={facebook}/>
                </div>
                <div className={classes.socialIcons}>
                  <img src={linkedin}/>
                </div>
              </div>

            </div>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
            <div className={classes.formContainer}>
              <div className={classes.cont}>
                <Text
                  title="SAY HELLO"
                  type="main"
                  style={{ color: "#58C2D7", fontSize: 22, marginTop: 0, marginBottom: 22 }}
                />
                <form onSubmit={handleSubmit} className={classes.formi}>
                  <div>
                    <div>
                      <Text
                        title="Name"
                        type="desc"
                        style={textStyle}
                      />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        className={classes.input}
                        placeholder="Name"
                      />
                    </div>
                    <div>
                      <Text
                        title="Company"
                        type="desc"
                        style={textStyle}
                      />
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={inputs.company || ""}
                        onChange={handleChange}
                        className={classes.input}
                        placeholder="Company"
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      <Text
                        title="Email"
                        type="desc"
                        style={textStyle}
                      />
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        className={classes.input}
                        placeholder="Email"
                      />
                    </div>
                    <div>
                      <Text
                        title="Phone"
                        type="desc"
                        style={textStyle}
                      />
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={inputs.phone || ""}
                        onChange={handleChange}
                        className={classes.input}
                        placeholder="Phone"
                      />
                    </div>
                  </div>

                  <div  className={classes.textareaContainer}>
                    <Text
                      title="Message"
                      type="desc"
                      style={textStyle}
                    />
                    <textarea
                      value={inputs.message}
                      onChange={handleChange}
                      name="message"
                      maxLength={100}
                      className={classes.textarea}
                      cols={3}
                      placeholder="Your message"
                    />
                  </div>

                  <div className={classes.submitContainer}>
                    <input type="submit" value="Submit" className={classes.submit} />
                    <img
                      src={require("../../assets/icons/right-arrow-white.png")}
                      alt="white-right-icon"
                      className={classes.submitIcon}
                    />
                  </div>

                </form>
                
              </div>
            </div>
            </GoogleReCaptchaProvider>
            <div>
              <img src={require('../../assets/contact/astronautImg.png')} alt="astronautImg" className={classes.astronautImg} />
            </div>
          </div>

          <div className={classes.carersContent} style={{ marginLeft: 38 }}>
            <div className={classes.carersInfo}>
              <div className={classes.carersText}>
                <h4>CAREERS</h4>
                <h5>Join our team to create the best digital solutions.</h5>
                <p>We’re a team of creatives who are excited about unique ideas and help fin-tech companies to create amazing identity by crafting top-notch UI/UX.</p>
              </div>
              <div>
                <button className={classes.contactButton}>
                  <a href='mailto:info@1up.al'>
                    See open positions
                  </a>
                </button>
              </div>
              <div>
                <MediaQuery minWidth={1200}>
                  <Suspense fallback={renderLoader()}>
                      <Footer />
                  </Suspense>
                </MediaQuery>
          </div>
            </div>
          </div>

          
        </div>
        
        
        <div className={classes.mapContainer}>

          <GoogleMapReact
             bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
            defaultCenter={center}
            defaultZoom={17}
          >
              <img
            lat={center.lat}
            lng={center.lng}
            src={require('../../assets/contact/pin.svg').default}
            style={{ width: 20, height: 'auto', objectFit: "contain" ,position: "relative",top: '-64.128px', cursor: 'pointer' }}
            alt="map-marker"
            onClick={handlePin}
          />
           {isShow && (
        <div className={classes.speechbubble} lat = {center.lat} lng={center.lng}>
          <img src={logo} className={classes.logo1up}/>
         <p className={classes.desc1up}> 1 UP LABS</p>
         <div className={classes.direction}><a href='https://www.google.com/maps/dir//41.3210392,19.8255591/@41.3209849,19.8255241,20z' style={{display : 'contents'}}> <img src={getDirection} className={classes.getDirection}/></a></div>
         
        </div>
        )}
          </GoogleMapReact>
        </div>
        <MediaQuery maxWidth={800}>
        <div className={classes.textContainer_1}>
                  <p className={classes.title}>LOCATION</p>
                  <p className={classes.text}>Rr.Themistokli Gërmenji Nr.1</p>
                  <p className={classes.text}>Tiranë 1001, Albania</p>
                </div>

                <Text
                  title="GET IN TOUCH"
                  type="main"
                  style={{ color: "#58C2D7", fontSize: 22, marginTop: 0, marginBottom: 22, marginLeft:'33px' }}
                />
                <div className={classes.textContainer}>
                <p className={classes.text}>
                  <img src={require('../../assets/contact/clock.svg').default} 
                    className={classes.msgImage}
                    alt="clock"
                    style={{marginRight:'7px'}}
                  />
                  Monday – Friday 9 am to 6 pm
                </p>
                
                <p className={classes.text}>  
                  <img src={require('../../assets/contact/mail.svg').default}
                    className={classes.msgImage}
                    alt="mail"
                    style={{marginRight:'7px'}}
                  />
                  <a href='mailto:info@1up.al'>
                    info@1up.al 
                  </a>
                </p>
                <div style={{ padding:'20px 0 20px 0px'}} className={classes.socialButtonsMobile}>
                <div className={classes.socialIcons}>                  
                  <img src={instagram}/>
                </div>
                <div className={classes.socialIcons}>
                  <img src={facebook}/>
                </div>
                <div className={classes.socialIcons}>
                  <img src={linkedin}/>
                </div>
              </div>
              </div>
              <Suspense fallback={renderLoader()}>         
                <Footer /> 
               </Suspense>

        </MediaQuery>
    </div>

  )
}

export default ContactCrazy;
