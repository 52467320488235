import React, { useEffect, useRef, useState, useMemo } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useParams } from 'react-router'

import classes from './PortfolioCrazyProject.module.css'

// import portfolioImg from '../../assets/portfolio/portfolio_update.png'

const PortfolioCrazyProject = () => {


  // const containerRef = useRef()
  // const bgRef = useRef()
  const timeoutRef = React.useRef(null)
  const { id } = useParams()
  //then fetch project data with that id


  const images = useMemo(() => [
    require('../../assets/portfolio/images/1.png'),
    require('../../assets/portfolio/images/2.png'),
    require('../../assets/portfolio/images/3.png'),
    require('../../assets/portfolio/images/4.png'),
    require('../../assets/portfolio/images/5.png'),
    require('../../assets/portfolio/images/6.png'),
    require('../../assets/portfolio/images/7.png'),
    require('../../assets/portfolio/images/8.png'),
    require('../../assets/portfolio/images/9.png'),
    require('../../assets/portfolio/images/10.png'),
    require('../../assets/portfolio/images/11.png')
  ], [])

  const [slideshowIndex, setSlideshowIndex] = useState(0);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  //animation of image zooming in and out on scroll

  // useEffect(() => {

  //   if (typeof window !== "undefined") {
  //     gsap.registerPlugin(ScrollTrigger)
  //   }

  //   ScrollTrigger.defaults({
  //     // Defaults are used by all ScrollTriggers
  //     toggleActions: "restart pause resume pause", // Scoll effect Forward, Leave, Back, Back Leave
  //     //markers: true // Easaly remove markers for production 
  //   })

  //   const timelineHeader = gsap.timeline({
  //     scrollTrigger: {
  //       id: "ZOOM",
  //       trigger: containerRef.current, // What element triggers the scroll
  //       scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
  //       start: "top top", // Start at top of Trigger and at the top of the viewport
  //       end: "+=100% 50px", // The element is 500px hight and end 50px from the top of the viewport
  //       pin: true, // Pin the element true or false
  //     }
  //   })

  //   timelineHeader.to(bgRef.current, {
  //     scale: 1
  //   }, "sameTime")


  // }, [])



  //Images slideshow 
  useEffect(() => {

    resetTimeout()

    timeoutRef.current = setTimeout(() => {

      setSlideshowIndex(slideshowIndex + 1)
      let b = images[0]
      images.push(b)
      images.shift()
      console.log(images)

    }, 2000)

  }, [slideshowIndex, images])



  const name = id === ':1' ? 'porsche' : 'porsche'
  console.log(id);

  const fontSize = 170 / (name.length)


  return (
    <div className={classes.container} >

      {/* Main Image that zooms in and out on scroll */}
      {/* <section className={classes.mainImageContainer} ref={containerRef} >
        <div className={classes.mainImage} ref={bgRef} ></div>
      </section> */}

      <section className={classes.mainImageContainer}>
        <div className={classes.mainImage}></div>
      </section> 

      <section className={classes.emptySection} ></section>

      {/* Background Name */}
      <span
        className={classes.backgroundTextContainer}
        style={{ zIndex: 3 }}
      >
        <h1
          className={classes.backgroundTitle}
          style={{ fontSize: `${fontSize}vw`, color: 'transparent' }}
        >
          {name}
        </h1>
      </span>
      <span
        className={classes.backgroundTextContainer}
        style={{ zIndex: -10 }}
      >
        <h1
          className={classes.backgroundTitle}
          style={{ fontSize: `${fontSize}vw`, color: '#fff' }}
        >
          {name}
        </h1>
      </span>

      <section className={classes.bottomSection} ></section>

      <section className={classes.bottomSectionContainer} >


        {/* Title */}
        <div className={classes.stairSection}>
          <div className={classes.titleConatiner} >
            <p className={classes.title} style={{fontFamily:'SophiaPro-Light'}}>Porsche Albania</p>
            <p className={classes.shortDescription} style={{fontFamily:'SophiaPro-Regular'}} >Lorem Ipsum is simply dummy text of the <b>printing</b> and <b>typesetting</b> industry.</p>
          </div>

          {/* Project Images */}

          <div className={classes.projectImagesContainer}>
            <img
              src={require('../../assets/portfolio/portfolio1.png')}
              className={classes.projectImage}
              alt="projectPhoto"
            />
            <img
              src={require('../../assets/portfolio/portfolio2.png')}
              className={classes.projectImage}
              alt="projectPhoto"
            />
          </div>

          <img
            src={require('../../assets/portfolio/stairs.png')}
            className={classes.stairsImage}
            alt="stairs"
          />

          <img
            src={require('../../assets/portfolio/globe.png')}
            className={classes.globeImage}
            alt="globe"
          />        
        </div>
       

        <div className={classes.firstDescriptionContainer} >
          <div className={classes.firstContentContainer} >
            <p className={classes.title} > Lorem Ipsum?</p>
            <p className={classes.desc} >
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
            <p className={classes.fadedDesc}>
              Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.
            </p>

            <div className={classes.borderContentContainer} >
              <p className={classes.bordeText} >We create world-class digital products, web design, and branding.</p>
            </div>
          </div>

          <div style={{ marginLeft: 100 }}>
            <img
              src={require('../../assets/portfolio/Galaxy-S20-Ultra.png')}
              className={classes.galaxy}
              alt="galaxyUltra"
            />
          </div>
        </div>

        <div className={classes.secondDescriptionContainer}>

          <img
            src={require('../../assets/portfolio/globe2.png')}
            className={classes.globeImage2}
            alt="globe"
          />
          <img
            src={require('../../assets/portfolio/picsRotating.png')}
            className={classes.rotatingPics}
            alt="rotatingPics"
          />

          <div style={{ width: 300 }}>
            <div className={classes.projectInfoContainer} >
              <p className={classes.projectinfoKeyword} >Digital Strategy</p>
              <p className={classes.projectinfoKeyword} >Branding</p>
              <p className={classes.projectinfoKeyword} >User Experience</p>
              <p className={classes.projectinfoKeyword} >Development</p>
              <p className={classes.projectinfoKeyword} >Web Design</p>
              <p className={classes.projectinfoKeyword} >Enterprise UX</p>
            </div>
            <p className={classes.additionalInfo}>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500</p>

            <div className={classes.linksContainer}>

              <img
                src={require('../../assets/portfolio/appStore.png')}
                className={classes.appStoreIcon}
                alt="appStoreLogo"
              />

              <img
                src={require('../../assets/portfolio/googlePlay.png')}
                className={classes.appStoreIcon}
                alt="googlePlayLogo"
              />

            </div>
          </div>
        </div>

        <div className={classes.bottomImageContainer}>
          <p className={classes.title}> Lorem Ipsum?</p>
          <p className={classes.desc}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          <div className={classes.fadedDescContainer}>
            <p className={classes.fadedDesc}>
              Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>

        <div className={classes.bottomContainer}>
          <p className={classes.fadedDesc}>SHARE</p>
          <div className={classes.shareContainer}>
            <img
              src={require('../../assets/portfolio/facebook.png')}
              alt="facebook"
              className={classes.shareIcon}
            />
            <img
              src={require('../../assets/portfolio/twitter.png')}
              alt="twitter"
              className={classes.shareIcon}
            />
            <img
              src={require('../../assets/portfolio/pinterest.png')}
              alt="pinterest"
              className={classes.shareIcon}
            />
          </div>

          {/* <div className={classes.randomImagesContainer}>
            {images.map(item => {
              let margintop = (images.indexOf(item) + 1) % 3 ? 0 : 100;
              margintop = (images.indexOf(item) + 2) % 3 ? margintop : 50;
              return (
                <img
                  src={item}
                  className={classes.randomImages}
                  style={{ marginTop: margintop }}
                  alt="projectPhotos"
                />
              )
            })}
          </div> */}
        </div>
      </section>
    </div>
  )

}



export default PortfolioCrazyProject;