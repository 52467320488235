import React from 'react'
import { Link } from 'react-router-dom'
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import classes from './HomeCrazy.module.css'
import Lottie from 'react-lottie';
import arrowdown from '../../assets/animations/arrowdown.json';
import posteri from '../../assets/images/home/cloud-poster.jpg';
import MediaQuery from 'react-responsive'
import Footer from '../../components/Footer'
import { Helmet } from 'react-helmet';

const HomeCrazy = props => {

  // let flyingSaucer = useRef()

  // useEffect(() => {

  //   if (typeof window !== "undefined") {
  //     gsap.registerPlugin(Draggable)
  //   }

  //   Draggable.create(flyingSaucer.current, {
  //     type: "x,y",
  //     bounds: window,
  //     dragResistance: 0.5,
  //     inertia: true,
  //   });

  // }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowdown,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    
    <div className={classes.container}>
       <Helmet>
      <title>Home Crazy</title>  
        <meta name="description" content="Home Crazy" />  
      </Helmet>
     <MediaQuery minWidth={800}>
      <MouseParallaxContainer  className={classes.astrounautsImage}
            containerStyles={{
              overflow:'initial',
              display: "block",
            }}
            resetOnLeave inverted>
  <MouseParallaxChild  factorX={0.05}
              factorY={0.05}>
  <img
        src={require('../../assets/images/home/astronaut.png')}
        alt="Astronauts"
        className={classes.astrounautsImage}

      />
    </MouseParallaxChild></MouseParallaxContainer>


       <div className={classes.videobackground}>
        <video
         poster ={posteri}
          playsInline
          autoPlay
          muted
          loop
          style = {{ width: '100%',height : '100%'}}
          src={require("../../assets/video/Video_3.mp4")}
        />
      </div>

      <div className={classes.contentContainer}>
        <Link to="/portfolio/creative" className={classes.linkContainer}>
          <p className={classes.centerText}>Our mission is to lead in the creation
            and development of the most advanced technology systems and deliver more value
            to your business.</p>
          <p className={classes.linkText}>Our Work</p>

          <Lottie
	          options={defaultOptions}
            width={50}
            height={100}
          />
          {/* <img
            src={require('../../assets/icons/downArrow.png').default}
            alt="Down Arrow"
            className={classes.linkImg}
          /> */}
        </Link>

      </div>
      </MediaQuery>
      <MediaQuery maxWidth={800}>
        <div className={classes.backgroundx2}>
        <img
        src={require('../../assets/images/home/astronaut1.png')}
        alt="Astronaut"
        className={classes.astrounautImage}

      />
      <Link to="/portfolio/creative" className={classes.linkContainer}>
          <p className={classes.centerText}>Our mission is to lead in the creation
            and development of the most advanced technology systems and deliver more value
            to your business.</p>
          <p className={classes.linkText}>Our Work</p>
          <div className={classes.arrowlottie}>
          <Lottie
	          options={defaultOptions}
            width={50}
            height={100}
            
          />
          </div>
      </Link>
      <div className='footeri'>
      <Footer/>
      </div>
        </div>
      </MediaQuery>
    </div>
    
  )
}

export default HomeCrazy
