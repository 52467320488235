//types 
const types = {
  TITLE: 'title',
  MAIN: 'main',
  DESC: 'desc',
}

let width = window.innerWidth;
console.log(width)

const Text = ({
  title,
  type,
  color,
  style,
}) => {
  let defaultStyle;
  if (type === types.TITLE) {
    defaultStyle = styles.title(color ? color : '#fff')
  } else if (type === types.MAIN) {
    defaultStyle = styles.main
  } else if (type === types.DESC) {
    defaultStyle = styles.desc
  }
  return <p style={{ ...defaultStyle, ...style }} > {title} </p>
}

const styles = {
  title: (color) => ({
    color: color,
    fontFamily: 'SofiaPro-Bold',
    fontSize: width < 600 ? 15 : 18,
  }),

  main: {
    color: '#fff',
    fontFamily: 'SofiaPro-Light',
    fontSize: width < 600 ? 15 : 22,
  },

  desc: {
    color: '#fff',
    fontFamily: 'SofiaPro-light',
    fontSize: width < 600 ? 12 : 18,
    opacity: 0.5,
  }
}

export default Text
