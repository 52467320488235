import React from 'react'
import classes from './Error.module.css'
import ErrorPhoto from "../../assets/error.png"
import MobileError from "../../assets/mobileError.png"
import MediaQuery from 'react-responsive'

const Error = () => (
  <div>
    {/* Mobile Version */}
    <MediaQuery maxWidth={800} className={classes.Mobilecontainer}>
      <img src={MobileError} className={classes.MobileErrorPhoto} alt='404 img'/>
    </MediaQuery>

     {/* Desktop Version */}
    <MediaQuery minWidth={800} className={classes.Desktopcontainer}>
       <img src={ErrorPhoto} className={classes.DesktopErrorPhoto} alt='404 img'/>
    </MediaQuery>
   </div>
 );
 
export default Error;