import { useEffect} from "react";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import ForthPage from './ForthPage';
import FifthPage from './FifthPage';


import "./horizontal.css"
gsap.registerPlugin(ScrollTrigger);

export default function IndexPage() {
  


  useEffect(() => {
    const sections = gsap.utils.toArray(".panel");

    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".horizontalContainer",
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1,
        scrub: 1.23,
        end: () => "+=" + document.querySelector(".horizontalContainer").offsetWidth
      }
    });
  }, []);



  return(
    <>
      <div className="horizontalContainer">
          <section className="panel" style={{width:"100%", height:"100%"}}>
            <ForthPage/>
          </section>
          <section className="panel" style={{width:"100%", height:"100%"}}>
            <FifthPage/>
          </section>        
      </div>
    </>
  )
}
