import React, { useState, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { setYourSide } from "../../src/store/action";
import classes from "./Header.module.css";
import Menu from "./Menu";
import SocialBtns from "./SocialBtns";
import adressBackground from "../assets/adressInfoBg.png";
import MediaQuery from "react-responsive";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import formClasses from "../screens/ContactSerious/JoinOurTeam.module.css";
import axios from "axios";

const Header = () => {
  //toggle menu
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [joinModal, setJoinModal] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [userCV, setUserCV] = useState(undefined);
  const [message_status, setMessage_status] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [capVal, setCapVal] = useState(null);
  let location = useLocation();
  const side = useSelector((state) => state.side);
  //menu data
  const SidebarData = [
    { title: "About us", path: side ? "/about/creative" : "/about" },
    { title: "Portfolio", path: side ? "/portfolio/creative" : "/portfolio" },
    { title: "Blog", path: "/blog" },
    { title: "Contact us", path: side ? "/contact/creative" : "/contact" },
  ];
  let navClass = classes.NavBarHide;
  if (sidebar) {
    navClass = classes.NavBarShow;
  } else {
    navClass = classes.NavBarHide;
  }
  //To stop body scrolling when menu is opened.
  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  });
  const Location = [
    // {
    //   title: "home",
    //     serious : '/home',
    //     creative : '/home/creative'
    // },

    {
      title: "about",
      serious: "/about",
      creative: "/about/creative",
    },

    {
      title: "portfolio",
      serious: "/portfolio",
      creative: "/portfolio/creative",
    },
  ];

  const crazySide = () => {
    let url = window.location.href;

    if (url.includes("/creative")) {
      return {
        opacity: "1",
      };
    } else {
      return {
        opacity: "0.2",
      };
    }
  };

  const seriousSide = () => {
    let url = window.location.href;

    if (!url.includes("/creative")) {
      return {
        opacity: "1",
      };
    } else {
      return {
        opacity: "0.2",
      };
    }
  };
  const refresh = () => {
    window.reload(false);
  };

  const ok = Location.map((el) => {
    if (location.pathname.includes(el.serious) && el.creative) {
      return (
        <div key={el.key} className={classes.sideTextHeader}>
          {/* <NavLink  to = {el.serious}  onClick={refresh} style={({isActive})=> isActive ? activeClass : nonActiveClass}><span style={{ marginRight:10,paddingRight:5,cursor:'pointer'}}>SERIOUS</span></NavLink>
    <NavLink  to = {el.creative} onClick={refresh} style={({isActive})=> isActive ? activeClass : nonActiveClass} ><span style={{ marginRight:10,paddingRight:5,cursor:'pointer'}}>CRAZY</span></NavLink> */}
          <NavLink to={el.serious} onClick={refresh} style={seriousSide}>
            <span
              style={{ marginRight: 10, paddingRight: 5, cursor: "pointer" }}
            >
              SERIOUS
            </span>
          </NavLink>
          <NavLink to={el.creative} onClick={refresh} style={crazySide}>
            <span
              style={{ marginRight: 10, paddingRight: 5, cursor: "pointer" }}
            >
              CRAZY
            </span>
          </NavLink>

          <span></span>
        </div>
      );
    }
  });

  // const registerUser = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }
  //   const result = await executeRecaptcha("register");
  //   setToken(result);
  // }, [executeRecaptcha]);

  // useEffect(() => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }
  //   const handleReCaptchaVerify = async () => {
  //     const token = await executeRecaptcha("register");
  //     setToken(token);
  //   };
  //   handleReCaptchaVerify();
  // }, [executeRecaptcha]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // registerUser();

    console.log("This is email:", email);
    console.log("This is fullname:", fullName);
    console.log("This is userCV:", userCV);

    try {
      const formData = new FormData();
      formData.append("full-name", fullName);
      formData.append("your-email", email);
      formData.append("your-cv", userCV);
      formData.append("_wpcf7_unit_tag", "72b942c");
      // formData.append("_wpcf7_recaptcha_response", token);

      const response = await axios.post(
        "https://cms.1up.al/wp-json/contact-form-7/v1/contact-forms/848/feedback",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${tokenParsed}`,
          },
        }
      );

      console.log(response);
      setMessage_status(response.data.message);
      setEmail("");
      setFullName("");
      setUserCV(undefined);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div>
      {joinModal && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              fontSize: "20px",
              color: "white",
              bottom: "50px",
              cursor: "pointer",
            }}
            onClick={() => {
              setJoinModal(!joinModal);
            }}
          >
            Close
          </div>

          <div style={{ width: "100%", maxWidth: "700px" }}>
            <div
              className={formClasses.formContainerContent}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={formClasses.formGreeting}>
                <h4>Get in touch</h4>
                <div className={formClasses.infoContact}>
                  <div className={formClasses.msgCelContact}>
                    <div className={formClasses.infoContactDay}>
                      <p className={formClasses.text}>
                        <img
                          src={require("../assets/contact/clock.svg").default}
                          className={formClasses.msgImage}
                          alt="clock"
                        />
                        Monday – Friday(9 am to 6 pm)
                      </p>
                    </div>
                    <div className={formClasses.infoContactCel}>
                      <p className={formClasses.text}>
                        <img
                          src={require("../assets/contact/mail.svg").default}
                          className={formClasses.msgImage}
                          alt="mail"
                        />
                        info@1up.al
                      </p>
                    </div>
                  </div>

                  <div className={formClasses.verticalLine}></div>
                  <div className={formClasses.infoContactLocation}>
                    <p className={formClasses.title}>Location</p>
                    <p className={formClasses.text}>
                      Rr.Themistokli Gërmenji Nr.1 Tiranë 1001, Albania
                    </p>
                  </div>
                </div>
              </div>

              {/* <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_SITE_KEY}
              > */}
              <div className={formClasses.contactFormContent}>
                <h4>tell us more about yourself</h4>

                <form
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                  }}
                >
                  <div style={{ width: "93%" }}>
                    <p>Name</p>
                    <input
                      type="text"
                      id="fullname"
                      name="fullname"
                      value={fullName}
                      onChange={(event) => {
                        setFullName(event.target.value);
                      }}
                      className={formClasses.input2page}
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  <div style={{ width: "93%" }}>
                    <p>Email</p>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      className={formClasses.input2page}
                      placeholder="Enter your email"
                      required
                    />
                  </div>

                  {/* <div className={formClasses.formGroupSubmit}> */}

                  <p>Upload your CV</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <input
                      encType="multipart/form-data"
                      type="file"
                      style={{ height: "67px", width: "70%" }}
                      onChange={(event) => setUserCV(event.target.files[0])}
                      name="cv"
                      placeholder="Your CV"
                      required
                    />

                    <button
                      // disabled={true}
                      type="submit"
                      style={{
                        backgroundColor: "transparent",
                        outline: "none",
                        borderRadius: "2px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "rgba(225, 225, 225, 0.2)",
                        height: "100px",
                        width: "20%",
                      }}
                    >
                      <img
                        src={require("../assets/contact/send.svg").default}
                        alt="white-right-icon"
                        className={formClasses.submitIcon}
                      />
                    </button>
                  </div>

                  {/* </div> */}
                  <div className="message_status">
                    {message_status ? <p>{message_status}</p> : null}
                  </div>
                </form>
              </div>
              {/* </GoogleReCaptchaProvider> */}
            </div>
          </div>
        </div>
      )}

      {!sidebar && (
        <div className={classes.NavBar}>
          <div className={classes.TopNav}>
            <Link to="/">
              <img
                src={require("../assets/icons/1upLogo.png")}
                alt="1up logo"
                className={classes.logo}
              />
            </Link>
            <MediaQuery minWidth={800}>{ok}</MediaQuery>

            <div className={classes.rightContainer}>
              <Link
                to="#"
                style={{ textDecoration: "none" }}
                onClick={showSidebar}
              >
                <MediaQuery minWidth={800}>
                  <Menu
                    dark={location.pathname.includes("/contact") ? true : false}
                  />
                </MediaQuery>
                <MediaQuery maxWidth={800}>
                  <Menu />
                </MediaQuery>
                {/* dark={(location.pathname === '/contact/creative') ? true : false} */}
              </Link>
            </div>
          </div>
        </div>
      )}
      <nav className={navClass}>
        {/* Top menu */}
        <div className={classes.TopNav}>
          <Link to="/">
            <img
              src={require("../assets/icons/1upLogo.png")}
              alt="1up logo"
              className={classes.logo}
              onClick={showSidebar}
            />
          </Link>
          <div className={classes.rightContainer}>
            <Link
              to="#"
              onClick={showSidebar}
              style={{ textDecoration: "none" }}
            >
              <Menu close={true} dark={true} />
            </Link>
          </div>
        </div>

        {/* Menu items  */}
        <div className={classes.menuInfoContainer}>
          <div className={classes.menuLinksContainer}>
            {SidebarData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classes.menuLinks}
                  onClick={showSidebar}
                >
                  <p className={classes.menuNumbers}>
                    {index + 1}.&nbsp; {!index && <>&#8201;</>}{" "}
                  </p>
                  <Link key={index} to={item.path} className={classes.linkItem}>
                    {item.title}
                  </Link>
                </div>
              );
            })}

            {/* Join our Team */}
            <div className={classes.menuLinks} style={{ cursor: "pointer" }}>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "10px",
                }}
              >
                <div className={classes.menuNumbers} style={{ color: "white" }}>
                  5.
                </div>
                <div
                  className={classes.linkItem}
                  onClick={() => setJoinModal(!joinModal)}
                >
                  Join our Team
                </div>
              </div>
            </div>

            {/* Address Info */}
            <div className={classes.addressInfo}>
              <img
                src={adressBackground}
                className={classes.adressBackgroundPhoto}
                alt="adress background img"
              />
              <div className={classes.addressTextContainer}>
                <p className={classes.address}>Get in Touch </p>
                <a
                  href="mailto:info@1up.al"
                  style={{ textDecoration: "none" }}
                  className={classes.addressText}
                >
                  St. Themistokli Germenji, 1001, Tiranë <br />
                  info@1up.al
                </a>
              </div>
            </div>
          </div>

          <Link to="/" onClick={showSidebar} className={classes.backContainer}>
            <img
              src={require("../assets/icons/backIconTest.svg").default}
              className={classes.leftArrowIcon}
              alt="left-arrow"
            />
            <p className={classes.backText}>BACK TO HOME</p>
          </Link>
          <div className={classes.hrLine}></div>

          {/* Social Icons */}
          <SocialBtns style={{ right: 35, left: "auto" }} />
        </div>
      </nav>
    </div>
  );
};

export default Header;
