import React from 'react'
import { Link } from 'react-router-dom'
import classes from './ImagesPos.module.css'
const ImagesPos = (props) => {
    
    
      return <div style={{ objectFit: 'contain', position: 'absolute', top: props.top, left: props.left, cursor: 'pointer' }} className={classes.bright}>

          <img src={props.path} className={classes.imgsize} />

          
      </div>
};



export default ImagesPos;
