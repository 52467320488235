/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet";

//import images
import topBackground from "../../assets/webTop1.png";
import bottomBackground from "../../assets/webBottom1.png";
import topBackgroundHover from "../../assets/webTopHover1.png";
import bottomBackgroundHover from "../../assets/webBottomHover1.png";
import chooseVersionBackground from "../../assets/chooseSideBackground.png";
import mobileTop from "../../assets/mobileTop.jpg";
import mobileBottom from "../../assets/mobileBottom.jpg";

import classes from "./Choose.module.css";

import { setYourSide } from "../../store/action";
import SocialBtns from "../../components/SocialBtns";

const Choose = (props) => {
  const [hoverTop, setHoverTop] = useState(false);
  const [hoverBottom, setHoverBottom] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Home" />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.chooseVersionContainer}>
          <img
            src={chooseVersionBackground}
            className={classes.chooseVersionBackground}
            alt="background lines img"
          />
          <p className={classes.sideTxt}>CHOOSE YOUR SIDE</p>
        </div>

        <div className={classes.photoContainer}>
          {/* top container; serious side */}
          {/* Mobile container */}
          <MediaQuery minWidth={800}>
            <div
              className={classes.topBackgroundStyle}
              style={{
                backgroundImage: hoverTop
                  ? `url(${topBackgroundHover})`
                  : `url(${topBackground})`,
              }}
              onPointerOver={() => setHoverTop(true)}
              onPointerOut={() => setHoverTop(false)}
              onClick={() => {
                dispatch(setYourSide(0));
                navigate("/home");
              }}
            >
              {hoverTop ? (
                <h1 className={classes.textTop}>SERIOUS ONE</h1>
              ) : (
                <h1></h1>
              )}
            </div>
          </MediaQuery>

          {/* Desktop container */}
          <MediaQuery maxWidth={800}>
            <div
              className={classes.topBackgroundStyleMobile}
              onClick={() => {
                dispatch(setYourSide(0));
                navigate("/home");
              }}
            >
              <img
                src={mobileTop}
                className={classes.mobileImg}
                alt="mobile top img"
              />
              <h1 className={classes.textTop}>SERIOUS ONE</h1>
            </div>
          </MediaQuery>
          <div className={classes.horizontalLine}></div>

          {/* bottom container; crazy side */}
          {/* Mobile container */}
          <MediaQuery minWidth={800}>
            <div
              className={classes.bottomBackgroundStyle}
              style={{
                backgroundImage: hoverBottom
                  ? `url(${bottomBackgroundHover})`
                  : `url(${bottomBackground})`,
              }}
              onPointerOver={() => setHoverBottom(true)}
              onPointerOut={() => setHoverBottom(false)}
              onClick={() => {
                dispatch(setYourSide(1));
                navigate("/home/creative");
              }}
            >
              {hoverBottom ? (
                <h1 className={classes.textBottom}>CRAZY ONE</h1>
              ) : (
                <h1></h1>
              )}
            </div>
          </MediaQuery>

          {/* Desktop container */}
          <MediaQuery maxWidth={800}>
            <div
              className={classes.bottomBackgroundStyleMobile}
              onClick={() => {
                dispatch(setYourSide(1));
                navigate("/home/creative");
              }}
            >
              <img
                src={mobileBottom}
                className={classes.mobileImg}
                alt="mobile bottom img"
              />
              <h1 className={classes.textBottom}>CRAZY ONE</h1>
            </div>
          </MediaQuery>
        </div>

        {/* Social Icons */}
        <SocialBtns dark={true} />
      </div>
    </>
  );
};

export default Choose;
