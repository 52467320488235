import React, { useState, useEffect } from 'react'
import { useParams, useNavigate  } from "react-router-dom";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MediaQuery from 'react-responsive'


import '../../components/slider.module.css';

import Text from '../../components/Text'

import classes from './AboutCrazy.module.css'

import video from '../../assets/about-crazy/video.mp4'

import facebook from '../../assets/animations/facebook.svg'
import instagram from '../../assets/animations/instagram.svg'
import linkedin from '../../assets/animations/linkedin.svg'
import { Helmet } from 'react-helmet';

const AboutCrazy = props => {

  const { slug } = useParams();

  //Partners logos service
  const navigate = useNavigate()

  const[logo,setLogo] = useState([]);
  useEffect(  () => {
     (async () => {
      const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=17&_embed&per_page=50');
      const data = await response.json();
      const partnersLogo = data.map((datas) => {
      let partnersImg = datas?.['acf']?.["CreativeLogo"]?.['sizes']?.['medium'];


      console.log(partnersImg)

      return {
       logo : partnersImg,
      }
    });
    setLogo(partnersLogo);
  })();
  },[]);
  console.log(logo);
  
  //Projects service
  const [portfolio, setPortfolio] = useState([]);
  
  useEffect( () => {
      (async () => {
      const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=2&_embed&per_page=20');
      const data = await response.json();
      const portfoliodata = data.map((datas) => {
        let portfolioimg  =   datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.['source_url'];
        return {
          src: portfolioimg,
          // tags: prtags,
          slug : datas.slug
        }
      });
        setPortfolio(portfoliodata);
  })();
  },[]);


  const [email, setEmail] = useState()

 
  const projectImages = React.useMemo(() => [
    { logo: require("../../assets/about-crazy/projects/1.png") },
    { logo: require("../../assets/about-crazy/projects/2.png") },
    { logo: require("../../assets/about-crazy/projects/3.png") },
    { logo: require("../../assets/about-crazy/projects/4.png") },
    { logo: require("../../assets/about-crazy/projects/5.png") },
    { logo: require("../../assets/about-crazy/projects/6.png") },
    { logo: require("../../assets/about-crazy/projects/7.png") },
    { logo: require("../../assets/about-crazy/projects/8.png") },
    { logo: require("../../assets/about-crazy/projects/9.png") },
    { logo: require("../../assets/about-crazy/projects/10.png") },
  ], [])


  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("email", email)
  }

  const handleChange = (event) => setEmail(event.target.value)

  //Blog slider parameters
  const settings = {
		infinite: true,
		dots: false,
		arrows: false,
		slidesToShow: 8,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 5000,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true
			  }
			}
		]
	};

  //Logos slider parameters
  const settings2={
    infinite: false,
		dots: true,
		arrows: false,
		slidesToShow: 2,
		slidesToScroll: 2,
    rows:2,
  }

  const logosettings={
    infinite: false,
		dots: false,
		arrows: false,
		slidesToShow: 8,
		slidesToScroll: 8,
    rows:2,
  }
  
  return (
    <div className={classes.container}>
        <Helmet>
      <title>About Crazy</title>  
        <meta name="description" content="About Crazy" />  
      </Helmet>
      <div className={classes.verticalLine}></div>
      <div className={classes.backgroundContainer}>
        <h1 className={classes.headerTitle}> Creativity is intelligence having fun</h1>
      </div>
      

      <div className={classes.secondContainer}>
        <div className={classes.leftTextContainer}>
          <p>We innovate the way brands are build</p>
        </div>

        <div className={classes.rightTextContainer}>
          <div>
            <p>UI/UX</p>
            <p>Mobile App Development</p>
            <p>Website Development</p>
          </div>
          <div>
            <p>Software Development</p>
            <p>Loyalty Programs</p>
            <p>Content Creation</p>
          </div>
        </div>
      </div>

      <div className={classes.thirdContainer}>
        <h1 className={[classes.mainText, classes.greenColor].join(' ')}>“Brave brands change the world.</h1>
        <h1 className={classes.mainText}>We help create them.”</h1>
        <div className={classes.whiteContainerDesktopCrazy}>
          <MediaQuery minWidth={900}> 
              <div className='partnersSeriousContainerDesktopCrazy' >
                  <Slider {...logosettings}>
                    {logo.map((el) => {
                        return (
                          <div className={classes.partnerLogoDesktopCrazy}>
                            <img src={el.logo} alt='whiteLogo' className={classes.logoImgDesktopCrazy}></img>
                          </div>
                        )
                      })}
                  </Slider>   
              </div>
          </MediaQuery>
        </div>

        <MediaQuery maxWidth={900}>
          <div className='partnersContainerMobile' style={{height:'200px'}}>
            <Slider {...settings2}>
              {logo.map((el) => {
                  return (
                    <div className={classes.partnerLogoMobile}>
                      <img src={el.logo} alt='whiteLogo' className={classes.partnersLogoMobile}></img>
                    </div>
                  )
                })}
            </Slider>   
          </div>
        </MediaQuery>
        
        <MediaQuery minWidth={900}>
          <div className={classes.secondLine2}>
          </div>
        </MediaQuery>

        <div className={classes.projectsContainer}>
          <div  className="projectsContainerslides">
            <Slider {...settings}>
              {portfolio.map(el => {
                return (
                    <img
                      src={el.src}
                      className={classes.projectLogo}
                      alt="projectPhotos"
                      onClick={()=>{navigate(`/portfolio/project/${el.slug}`)}}
                    />
                )
              })}
            </Slider>
          </div>
         
        </div>

        <div className={classes.discoverButtonContainer}>
          <p>Want to see more ?</p>
          <button className={classes.discoverButton} onClick={()=>navigate('/portfolio/creative')}>
            <Text
              title="Discover all our projects"
              type='desc'
              style={{ fontSize: 14 }}
            />
          </button>
        </div>
        
        <div className={classes.fourthContainer}>
        <div className={classes.contactContainer}>
          <div className={classes.rowContainer}>
            <p className={classes.subscribeText}>
              Subscribe to our newsletter
            </p>
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              <input
                placeholder='Your email'
                className={classes.input}
                value={email}
                handleChange={handleChange}
                type='email'
              />
              <input
                type='submit'
                className={classes.submitButton}
                value='Subscribe'
              />
            </form>
            <p className={classes.privacyText}>Privacy Settings</p>
          </div>

          <div className={classes.aboutContainer}>
            <div>
              <p className={classes.text}>E: <a href = "mailto:info@1up.al">info@1up.al</a></p>

              <div className={classes.infoContact}>
                <p className={classes.text}>Rr.Themistokli Gërmenji Nr.1</p>
                <p className={classes.text}>Privacy Settings</p>
              </div>
            </div>
            <div style={{ marginLeft: 50 }} className={classes.socials}>
              <p className={classes.text}>Instagram</p>
              <p className={classes.text}>Twitter</p>
              <p className={classes.text}>LinkedIn</p>
              <div className={classes.copyright}>
                <p className={classes.text}>© {new Date().getFullYear()} 1UP Labs</p>
              </div>
            </div>
          </div>
     

            <MediaQuery maxWidth={900}>
              <div className={classes.infoContact}>
                <p className={classes.text}>Rr.Themistokli Gërmenji Nr.1 </p>
                <p className={classes.text}>Tiranë 1001, Albania</p>
              </div>
              <div className={classes.infoContact}>
                <p className={classes.text}>Monday – Friday (9 am to 6 pm)</p>
                <p className={classes.text}>info@1up.al</p>
              </div>
              <div style={{ position: "relative", marginTop:'20px',marginLeft:'0px'}} className={classes.socialButtons}>
                <div className={classes.socialIcons}>                  
                  <img src={instagram}/>
                </div>
                <div className={classes.socialIcons}>
                   <img src={facebook}/>
                </div>
                <div className={classes.socialIcons}>
                  <img src={linkedin}/>
                </div>                 
              </div>
            </MediaQuery>
        </div>
        <MediaQuery minWidth={900}>
          <div className={classes.videoContainer}>
            <video
              src={video}
              playsInline
              autoPlay
              loop
              muted
              alt="footer-background"
              className={classes.backgroundFooter}
              style={{width:'1789px', height:'735px', marginLeft:'3%'}}
            />
          </div>
        </MediaQuery>
          
        </div>
       
       
        <MediaQuery maxWidth={900}>
          <div className={classes.videoContainer}>
            <video
              src={video}
              playsInline
              autoPlay
              loop
              muted
              alt="footer-background"
              className={classes.backgroundFooter}
              style={{marginBottom:'-84px'}}
            />
          </div>
        </MediaQuery>

      </div>
    
    </div>
  )
}

export default AboutCrazy
