import React from "react";
import classes from './Logo2.module.css'
const Logo2 = () =>
{
    return (
    <div className={classes.item}>
        <h3 className={classes.blogu}> BLOG </h3>
    </div>)
};

export default Logo2;