import React ,{useRef, useEffect, useState} from "react";

import classes from './CrazyProject.module.css'
import Header from "../../components/Header";
import ReactPageScroller from 'react-page-scroller';

import SecondPage from "./SecondPage";

const PorscheOpen =()=>{
    // const[showComponent, setshowComponent]=useState(false)
    

    // const ref = useRef(null);

//     const handleScroll =()=>{
//         ref.current?.scrollIntoView({behavior:'smooth'});
//     };
//     function Scrolldown() {
//      window.scroll(0,300); 
// }

// window.onload = Scrolldown;
    // useEffect(()=>{
    //     setInterval(()=>{
    //         setshowComponent(!showComponent);
    //     }, 5000)
    // })
    const ref = useRef();

    useEffect(() => {
  
      const ok = setTimeout(()=>{
        ref.current?.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth"
        });
          }, 2000)
         
          return () => {clearInterval(ok);}
    },[]);

    // const load = () => {setInterval(()=>{
    //     ref.current?.scrollIntoView({behavior:"smooth", block: "start", inline:"auto"});
    //          }, 4000)}
    //     useEffect(() => { 
    //     load();
      
    // },[load]);
    const load = () => {setInterval(()=>{
        ref.current?.scrollIntoView({behavior:"smooth", block: "start", inline:"auto"});
             }, 4000)}
        useEffect(() => { 
        load();
      
    },[load]);
    
    // const scollToRef = useRef();
    // const handleClick = () => {
    //     scollToRef.current?.scrollIntoView({behavior: 'smooth'});
    // };   


    return(
        <>
        <div>
            <div className={classes.container}>
                {/* <Header/> */}

                <ReactPageScroller>
                <div className={classes.firstcontainer} >
                    <div className={classes.dropin}>
                        <img src={require('../../assets/portfolio/Porsche_Logo.png')}></img>
                    </div>
                    <p className={classes.dropinn} style={{fontFamily:"SofiaPro-Light"}}>Porsche Albania</p>
                    <div className={classes.loading}>
                        <p style={{fontFamily:"SofiaPro-Light"}}>Loading</p>
                    </div>
                </div>
            
                
                <div className={classes.secondcontainer} ref={ref}>
                    <p className={classes.title} style={{fontFamily:"SofiaPro-Light"}}>INTRO</p>
                    <div className={classes.minisecondcontainer}>
                        <div className={classes.photo}>
                            <img src={require('../../assets/portfolio/second_container.png')}></img>
                        </div>

                        <div className={classes.desc}>
                            <p className={classes.bigText} style={{fontFamily:"SofiaPro-Bold"}}>Self-Custody Crypto Wallet for DFT, NFT and Metaverse</p>
                            <p style={{fontFamily:"SofiaPro-Light"}}>Explore the list of 1000s Apps or connect Web3 App with a WalletConnect protocol</p>
                        </div>
                    </div>
                 
                </div>
                </ReactPageScroller>
            </div>
        </div>
        
       
           
        </> 
    )
 
}

export default PorscheOpen;