import React from "react";

import classes from './CrazyProject.module.css'

const ForthPage =()=>{
    return(
        <>
            <div className={classes.forthcontainer}>


                    <div className={classes.firstSection}>
                    <p className={classes.title} style={{fontFamily:"SofiaPro-Light", position:"relative", top:"-16rem"}}>DRIVE SAFE</p>

                        <div className={classes.desc1} style={{fontFamily:"SofiaPro-Light"}}>
                            <p className={classes.orangeBigText} style={{fontFamily:"SofiaPro-Bold"}}>Self-Custody Crypto Wallet for DFT, NFT and Metaverse</p>
                            <img src={require('../../assets/porsche/crazy/icons@2x.png')} className={classes.ikonaPorshi}></img>
                        </div>
                    </div>
                    

                    <div className={classes.photo1}>
                        <img src={require('../../assets/porsche/crazy/03@2x.png')} style={{width:"320px"}}></img>
                    </div>
            </div>
        </> 
    )
 
}

export default ForthPage;