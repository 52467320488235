import React from "react";

import classes from './SocialBtn.module.css'

const SocialBtns = props => {

  const instagramLogo = props.light ? require('../assets/contact/ig.svg').default : props.dark ? require('../assets/icons/instagram-dark.png') : require('../assets/icons/instagram.jpg');
  const fbLogo =  props.light ? require('../assets/contact/fb.svg').default : props.dark ? require('../assets/icons/fb-dark.png') : require('../assets/icons/fb.jpg');
  const linkedinLogo =  props.light ? require('../assets/contact/in.svg').default : props.dark ? require('../assets/icons/linkedin-dark.png') : require('../assets/icons/linkedin.jpg');


  return (
    <div
      className={props.light ? classes.lightSocialContainer : props.dark ? classes.darkSocialContainer : classes.socialContainer}
      style={props.style}
    >
      <a href="https://www.instagram.com/1up_labs">
        <img src={instagramLogo} alt="instagram logo" className={props.dark ? classes.darkSocialIcon : classes.socialIcon} />
      </a>
      <a href="https://www.facebook.com/1UPinteractive">
        <img src={fbLogo} alt="facebook logo" className={props.dark ? classes.darkSocialIcon : classes.socialIcon} />
      </a>
      <a href="https://www.linkedin.com/company/1up-labs">
        <img src={linkedinLogo} alt="linkedin logo" className={props.dark ? classes.darkSocialIcon : classes.socialIcon} />
      </a>
    </div>
  )
}

export default SocialBtns;