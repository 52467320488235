import React, { useEffect, useState, Suspense} from 'react'
import classes from './BlogItem.module.css'
import { useParams } from 'react-router-dom';
import  { Helmet,HelmetProvider } from 'react-helmet-async';

import MediaQuery from 'react-responsive'
import Footeri from './Footeri';
const BlogItem = props => {
  
  const [post, setPost] = useState([]);
  const { slug } = useParams();
  useEffect( () => {
    let isMounted = true;      
    (async () => {
      const response = await fetch(`https://cms.1up.al/wp-json/wp/v2/posts?categories=3&_embed&slug=${slug}`);
      console.log(response);
      const data = await response.json();
      const ok = data.map((datas) =>{
      let datat = new Date(datas.date).toLocaleDateString('en-GB', { day:"numeric", month:"long"});
        let src =  datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.['source_url'];
        let  src1 =  datas?.['acf']?.['mobile-cover']?.['url'];
        
        return{
          date: datat,
          title : datas.title.rendered,
          content: datas.content.rendered,
          src: src,
          src1: src1,
          slug : datas.slug,
          id: datas.id
        }
      });
         if(isMounted) setPost(ok);
    })(); 
    return () => { isMounted = false }; 
 },[slug]);
   if (!post) return null;
    console.log(post);
    const BlogPost = React.lazy(() => import('../BlogPosts/BlogPost'));
    const loadstyle = {
      display: 'flex',
      alignItems: 'center'
    };

    const Footer = React.lazy(() => import('../../components/Footer'));
    const ShareBlog = React.lazy(() => import('../../components/ShareBlog'));
    const renderLoader = () => <p>Loading</p>;
  return(
  <>
    {post.map((data)=> <div key={data.id} className={classes.post} >
    <HelmetProvider>
      <Helmet>
        <title>{data.title}</title>  
        <meta name="description" content={data.title} data-react-helmet="true"/>  
        <link rel={data.title} href={data.src} data-react-helmet="true" />  
        {/* <meta property="og:url" content=" https://1up.al/blog/"{...slug} /> */}
        <meta property="og:type" content="article" data-react-helmet="true" />
        <meta property="og:title" content={data.title} data-react-helmet="true" />
        <meta property="og:description" content={data.title} data-react-helmet="true" />
        <meta property="og:image" content={data.src} data-react-helmet="true" />
        {/* <meta property="fb:app_id" content="114156419207881" /> */}

      </Helmet>
      </HelmetProvider>
    <MediaQuery maxWidth={769}>
      <img className={classes.thumbnail} src={data.src1} alt='thumbnail' />
      </MediaQuery>
      <MediaQuery minWidth={769}>
      <img className={classes.thumbnail} src={data.src} alt='thumbnail' />
      </MediaQuery>
      <div className={classes.description}> 
       <p>{data.title} </p>
       <span>{data.date}</span>  
       </div>
      <div className={classes.supercont}>
        <div className={classes.kontent}>
          <div className={classes.paragraphs}>
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: data.content}} >
            </div>
        </div>
        <MediaQuery minWidth={769}>
        <div className={classes.komponente}>  
        <div className={classes.komp}>
        <Suspense fallback={<div style={loadstyle}>Loading...</div>}>
             <BlogPost/> 
             </Suspense> 
        </div>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={769}>
        <div className={classes.komponente}>  
        <Suspense fallback={<div style={loadstyle}>Loading...</div>}>
             <BlogPost/> 
             </Suspense>
        </div>
        </MediaQuery>
        </div>
        <div className={classes.butona}>
        {/* <SocialBtns dark ={true}/> */}
        <Suspense fallback={renderLoader()}>
        <ShareBlog dark = {true}/>
        </Suspense>
        </div>
        <MediaQuery maxWidth={769}>
        <div className='footeri'>
          <div className={classes.footeri}>
          <Suspense fallback={renderLoader()}>
        <Footer/>
        </Suspense>
        </div>
        <Footeri/>
        </div>
        </MediaQuery>
        <MediaQuery minWidth={769}>
        <div className='footeri'>
          <div className={classes.footeri}>
          <Suspense fallback={renderLoader()}>
        <Footer/>
        </Suspense>
        </div>
        </div>
        </MediaQuery>
      </div>
      </div>
    )}

  </>
);
}

export default BlogItem;