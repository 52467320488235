import React, { useEffect, useRef, useState, lazy, Suspense } from 'react'

import MediaQuery from 'react-responsive'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';


import classes from './HomeSerious.module.css'
import './slick.css'

import Text from '../../components/Text'

import Lottie from 'react-lottie'
import animationData from '../../assets/animations/mouseWhite.json'
import animationArrow1 from '../../assets/animations/RightArrow3.json'
import volume from '../../assets/animations/volume.json'
import mutedVolume from '../../assets/animations/muted.json'

import BlogMobile from './blogMobile';
import ShowMore from 'react-show-more';


  
const HomeSerious = (props) => {

  const { slug } = useParams();
  const [service,setService] = useState([]);
  useEffect(  () => {
     (async () => {
      const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=3&_embed');
      const data = await response.json();
      const blogServices = data.map((datas) => {
      let servicethumbnail = datas?.['acf']?.['thumbnail']?.['url'];
      let datat = new Date(datas.date).toLocaleDateString('en-GB', {  day:"numeric", month:"long"});
      if(!servicethumbnail){
        servicethumbnail = 'https://1up.al/static/media/1upLogo.09249cdd.png';
      }
      return {
        id: datas.id,
        description: datas.title.rendered,
        date: datat,
        src: servicethumbnail,
        slug: datas.slug
      }
    });
    setService(blogServices);
   })();
  },[]);
  // console.log(service);
  let blogposts = [];
  blogposts = service.filter(e => e.slug !== slug ); 
  // console.log(blogposts)

  const[testemonials,setTestemonials] = useState([]);
  useEffect(  () => {
     (async () => {
      const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=18&_embed');
      const data = await response.json();
      const partnersTestimonials = data.map((datas) => {
      let name = datas?.['acf']?.["emri_komentuesit"];
      let desc = datas?.['acf']?.["pershkrimi_komentuesit"];
      let clientLogo = datas?.["_embedded"]?.["wp:featuredmedia"]?.[0]?.["media_details"]?.["sizes"]?.["full"]?.["source_url"];
      let noOfStars=5;
      
      return {
        clientName : name,
        clientDesc : desc,
        logo : clientLogo,
        noOfStars  :noOfStars
      }
    });
    setTestemonials(partnersTestimonials);
    
  })();
  },[]);
  console.log(testemonials)
 

  let setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        }
      }
    ]
  }
  let scroll1 ={
    dots: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    centerMode:true
  }
  let feedbackScroll={
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    // centerMode:true,

  }
  let feedbackScroll1={
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode:true,

  }

  const videoRef = useRef()
  
  const ref = useRef(null);
  const navigate = useNavigate()
 
  
  
  const handleClick =()=>{
    ref.current?.scrollIntoView({behavior:'smooth'});
  };

  
  
  const services = [
    { title: 'UX/UI', image: require("../../assets/about/pencil-and-ruler.png"), desc: 'Intuitive, accessible, and user-friendly customer experiences.'},
    { title: 'Mobile App Development', image: require("../../assets/home/Mobile.png"), desc: 'Mobile app development tailored to your needs for iOS and Android' },
    { title: 'Website Development', image: require("../../assets/about/internet.png"), desc: 'Aesthetically pleasing, professional and easy-to-use websites'},
    { title: 'Software Development', image: require("../../assets/home/software1.png"), desc: 'Custom software that helps your business thrive.'},
    { title: 'Loyalty Programs', image: require("../../assets/about/megaphone.png"), desc: 'Best in class cases of customer rewarding schemes.' },
    { title: 'Content Creation', image: require("../../assets/home/Content.png"), desc: 'We take care of your online presence'},
  ]


  const defaultSettings ={
    loop:true,
    autoplay:true,
    animationData:animationData,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    }
  }
  const defaultSettings2 ={
    loop:true,
    autoplay:true,
    animationData:animationArrow1,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    }
  }
  
  const volumeSettings ={
    loop:true,
    autoplay:true,
    animationData:volume,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    }
  }
  const volumemutedSettings ={
    loop:true,
    autoplay:true,
    animationData:mutedVolume,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    }
  }
  
  const Footer = lazy(() => import('../../components/Footer'));
  const BlogMobile = lazy(() => import('./blogMobile'));
  const renderLoader = () => <p>Loading</p>;

  const [muted, setMuted]= useState(true)
  const handleToggleMute=()=>setMuted(current=>!current);


  

  return (
    
    <div className={classes.container}>
      <Helmet>
      <title>Home Serious</title>  
        <meta name="description" content="Home Serious" />  
      </Helmet>
      
      <button onClick={handleToggleMute} style={{background:'transparent'}} className={classes.volumeContainer}> 
        <div>
          <Lottie
            options={muted ? volumemutedSettings : volumeSettings}
            className={classes.voulumeIcon}
            height={50}
            width={50}
            style={{color:'white', borderRadius:'50%', background:'black'}}
          />
        </div>
      </button>
      <MediaQuery minWidth={800}>
        <div className={classes.video}>
          <video
            playsInline
            autoPlay
            loop
            muted ={muted}
            src={require("../../assets/video/Introduction_1UP_Labs.mp4")}
            ref={videoRef}
          />
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={800}>
        <div className={classes.video}>
          <video
            playsInline
            autoPlay
            loop
            muted ={muted}
            src={require("../../assets/video/Introduction_1UP_Website_Mobile.mp4")}
            ref={videoRef}
          />
        </div>
      </MediaQuery>
      


      <div className={classes.mouseIcon} onClick={handleClick}>
        <Lottie
          options={defaultSettings}
          className={classes.mouseIcon}
          height={75}
          width={40}
          style={{marginTop:20, color:'white'}}/>
      </div>
      
      <div className={classes.abouContainer} ref={ref} >
        <div className={classes.aboutTextContainer} >
          <div className={classes.main}>
            <p style={{fontFamily:'Montserrat,sans-serif'}}>Innovating the way brands are built</p>
          </div>
          <div className={classes.desc}>
            <p style={{fontFamily:'Montserrat,sans-serif', marginTop:0}}>We’re innovative and customer-obsessed providing only success stories.</p>
          </div>
        </div>

        <div className={classes.aboutEmailContainer}>
          {/* <p style={{fontFamily:'Montserrat,sans-serif'}} className={classes.email}></p> */}
        </div>
      </div>

      <MediaQuery minWidth={800}>
        <div className={classes.servicesContainer}>
          {services.map(item => {
            return (
              <div className={classes.serviceItemContainer}>
                <img
                  src={item.image}
                  alt='internet'
                  className={classes.serviceItemImage}
                />
                <div className={classes.serviceTextContainer}>                 
                  <p className={classes.servicesMain} style={{fontFamily:'SofiaPro-Medium',margin:0, width:10}}>{item.main}</p>
                  <p className={classes.servicesTitle} style={{fontFamily:'SofiaPro-Medium', margin:0 }}>{item.title}</p>
                  <p className={classes.servicesDesc} style={{fontFamily:'Montserrat,sans-serif', margin:0, lineHeight:1.3,paddingTop:'5px',width:'76.6%'}}>{item.desc}</p>
                </div>  
              </div>
            )
          })}
        </div>
      </MediaQuery>
      
      <MediaQuery maxWidth={800}>
      <div className='servicesContainer'>
        <Slider  {...setting}>
            {services.map(item => {
              return (
                <div className={classes.serviceItemContainerMobile}>
                  <img
                    src={item.image}
                    alt='internet'
                    className={classes.serviceItemImageMobile}
                    
                  />
                  <div className={classes.serviceTextContainerMobile}>                 
                    <p className={classes.servicesMainMobile} style={{fontFamily:'SofiaPro-Medium'}}>{item.main}</p>
                    <p className={classes.servicesTitleMobile} style={{fontFamily:'SofiaPro-Medium'}}>{item.title}</p>
                    <p className={classes.servicesDescMobile} style={{fontFamily:'Montserrat,sans-serif'}}>{item.desc}</p>
                  </div>  
                </div>
              )
            })}
         
        </Slider>
        </div>
      </MediaQuery>
     
      <div className={classes.imageContainer}>
        <img
          src={require('../../assets/home/home-serious-1.png')}
          alt='home-serious-1'
          className={classes.firstImage}
        />
        <img
          src={require('../../assets/home/home-serious-2.png')}
          alt='home-serious-2'
          className={classes.secondImage}
        />
      </div>

      <div className={classes.projectContainer}>
        <div className={classes.projectContainerText}>
          <div className={classes.projectContainerPartOne}>
            <div className={classes.projectContainerTitle}>
              <p style={{fontFamily:'SofiaPro-SemiBold' ,color:'#58C2D7'}}>100+</p>
            </div>
            <div className={classes.projectContainerMain}>
              <p style={{fontFamily:'SofiaPro-Medium'}}>Succeeded projects</p>
            </div>
            <div className={classes.projectContainerDesc}>
              <p style={{fontFamily:'Montserrat,sans-serif'}}>Experience with global brands: Vodafone, Coca-Cola, JTI and Red Bull. 
              <br></br>
              Exporting products in the international market: JTI, Samsung, Sae</p>
            </div>
          </div>
          <div className={classes.projectContainerPartTwo}>
            <div className={classes.projectContainerTitle}>
              <p style={{fontFamily:'SofiaPro-SemiBold' , color:'#58C2D7'}}>21K+</p>
            </div>
            <div className={classes.projectContainerMain2}>
              <p style={{fontFamily:'SofiaPro-Medium'}}>Working hours were spent</p>
            </div>
            <div className={classes.projectContainerDesc2}>
              <p style={{fontFamily:'Montserrat,sans-serif'}}>We are an energetic, creative, productive young team, who works on technological innovations</p>
            </div>
          </div>
        </div>

        <div className={classes.blogTextContainer}>
          <div className={classes.verticalLine} />
          <p className={classes.blogText} style={{fontFamily:'SofiaPro-Medium'}}>BLOG</p>
        </div>
      </div>

      <MediaQuery minWidth={800}>
        <div className='slideshowContainer'>
          <Slider {...setting}>
            {blogposts.map((el) => { 
              return (
                <div key = {el.id} className={classes.imgContainer} >
                  <a href ={`/blog/${el.slug}`} >         
                    <img src={el.src} className={classes.slideshowImg} alt='blog img'></img>
                    <div className={classes.imgText}>
                      <p style={{fontSize:20, fontFamily:'Montserrat,sans-serif', color:'#FFFFFF', textAlign:'left'}}>{el.description} </p>
                      <span style={{fontSize:14, fontFamily:'SofiaPro-Medium',opacity:'0.45', textAlign:'left'}}>{el.date}</span>    
                    </div>      
                  </a> 
                </div>
                )
              }
            )}  
          </Slider>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={800}>

        <Suspense fallback={renderLoader()}>
          <BlogMobile/>
        </Suspense>
      </MediaQuery>


      <div className={classes.feedContainer}>
        <Text
          title='What people are saying'
          type='main'
          style={{fontSize:18, fontFamily:'SofiaPro-Medium', lineHeight:'5px'}}
        />
        <MediaQuery minWidth={800}>
          <Text
            title='Our partners are with us for the long term. Hear about collaboration straight from the source. Check out some of the highlights of our collaboration so far.'
            type='desc'
            style={{ marginTop: -5, width: '20%', textAlign: 'center', fontSize:14, fontFamily:'Montserrat,sans-serif'}}
          />
        </MediaQuery>
        <MediaQuery maxWidth={800}>
          <Text
            title='Our partners are with us for the long term. Hear about collaboration straight from the source. Check out some of the highlights of our collaboration so far.'
            type='desc'
            style={{ marginTop: -5, width: '87%', textAlign: 'center', fontSize:14, fontFamily:'Montserrat,sans-serif'}}
          />
        </MediaQuery>
      </div>

        {/* <Content/> */}
      <div className='feedbackSlider'>
        <div className={classes.feedbackTextContainer}>
          <MediaQuery minWidth={800}>
            <Slider {...feedbackScroll}>
              {testemonials.map((el) => { 
                const startArray = [...Array(el.noOfStars).keys()]
                  return (
                      <div className={classes.singlefeedbackContainer}>
                        <div className={classes.feedbackContentContainer}>
                          <div className={classes.clientLogo}>
                            <img src={el.logo}  className={classes.profileImage} alt='partner logo'></img>
                            <p  style={{ fontSize:16, fontFamily:'SofiaPro-Medium'}}>{el.clientName}</p>
                          </div>
                          <p style={{ marginTop: -15,fontSize:14, fontFamily:'Montserrat,sans-serif', opacity:'0.5'}}>
                          <ShowMore lines={3} anchorClass=''>
                            <p style={{ fontSize:14, fontFamily:'Montserrat,sans-serif'}} className={classes.descClient}>{el.clientDesc}</p>
                          </ShowMore>
                          </p>
                              
                    
                          <div className={classes.feedbackDesc}>
                            {/* <p  style={{ marginTop: -15,fontSize:14, fontFamily:'Montserrat,sans-serif', opacity:'0.5'}}>{el.clientDesc}</p> */}
                          </div>
                        </div>
                        <div className={classes.stars}>
                          {startArray.map(() => <img src={require('../../assets/icons/star.png')} alt='star' className={classes.startIcon} />)}
                        </div>

                      </div>
                  )
                }
              )}  
            </Slider>
          </MediaQuery>
        </div>
      </div>
    

      <div className='feedbackSliderMobile'>
        <MediaQuery maxWidth={800}>
          <Slider {...feedbackScroll1}>
            {testemonials.map((el) => { 
              const startArray =[...Array(el.noOfStars).keys()]
              return (
                    <div className={classes.singlefeedbackContainerMobile}>
                      <div className={classes.feedbackContentContainer}>
                        <div className={classes.clientLogo}>
                          <img src={el.logo}  className={classes.profileImage} alt='partner logo'></img>
                          <p  style={{ fontSize:13, fontFamily:'SofiaPro-Medium'}}>{el.clientName}</p>
                        </div>
                        <p style={{ fontSize:11, fontFamily:'Montserrat,sans-serif', opacity:'0.5'}}>
                          <ShowMore lines={2} anchorClass=''>
                            <p >{el.clientDesc}</p>
                          </ShowMore>
                        </p>
                      </div>
                      <div className={classes.starsMobile}>
                        {startArray.map(()=> <img src={require('../../assets/icons/star.png')} alt='star' className={classes.startIcon} />)}
                      </div>
                    </div>
                )
              }
            )}  
          </Slider>
        </MediaQuery>
      </div>

      <div className={classes.contactContainer} >
        <div className={classes.contactTextContainer}>
          <MediaQuery maxWidth={800}>
            <Text
              title='Let’s talk.'
              type='main'
              style={{ fontSize: '40px', marginBottom:'0px', fontFamily:'SofiaPro-Medium',  marginLeft:'0px'}}
            />
            <Text
              title='We are nimble, quick and flexible.'
              type='desc'
              style={{ fontSize: 14, marginBottom: -10, fontFamily:'Montserrat,sans-serif', opacity:'0.5', marginLeft:'0px',lineHeight:'10px',paddingTop:'5px'}}
            />
            <Text
              title='We create different and better solutions that drive superior brand growth.'
              type='desc'
              style={{ fontSize: 14, marginBottom: 10, fontFamily:'Montserrat,sans-serif', opacity:'0.5', marginLeft:'0px', paddingLeft:'5px', paddingRight:'5px'}}
            />
          </MediaQuery>

          <MediaQuery minWidth={800}>
            <Text
              title='Let’s talk.'
              type='main'
              style={{ fontSize: '50px', marginBottom:'0px', fontFamily:'SofiaPro-Medium',  marginLeft:'30%'}}
            />
            <Text
              title='We are nimble, quick and flexible.'
              type='desc'
              style={{ fontSize: 14, marginBottom: -10, fontFamily:'Montserrat,sans-serif', opacity:'0.5', marginLeft:'30%',lineHeight:'10px',paddingTop:'5px'}}
            />
            <Text
              title='We create different and better solutions that drive superior brand growth.'
              type='desc'
              style={{ fontSize: 14, marginBottom: -10, fontFamily:'Montserrat,sans-serif', opacity:'0.5', marginLeft:'30%'}}
            />
          </MediaQuery>
        </div>
        
        <button
          className={classes.contactButton}
          onClick={() => navigate('/contact')}
          >
          <p style={{padding:'10px'}}>CONTACT US</p>
          <img
            src={require('../../assets/icons/right-arrow-white.png')}
            alt='right-arrow'
            className={classes.contactButtontIcon}
          />
        </button>
      </div>

      <div>
        <a href='./about' className={classes.aboutContainer}>
          <Text
            title='NEXT'
            type='desc'
            style={{ fontSize:14, fontFamily:'Montserrat,sans-serif'}}
          />

          <Text
            title='About Us'
            type='main'
            style={{ marginTop: -7,fontSize:22, fontFamily:'SofiaPro-Medium'}}
            className={classes.aboutMain}
          />

          <div className={classes.arrowRight}>
            <Lottie
            options={defaultSettings2}
            className={classes.arrowRight}
            height={33}
            width={80}
            style={{color:'white', width:'150px'}}/>
          </div>
        </a>
      </div>

      <div className={classes.footerHome}>
      <Suspense fallback={renderLoader()}>
        <Footer />
      </Suspense>
      </div>
    </div >
  )
}

export default HomeSerious