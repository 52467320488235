import React, { useRef } from 'react';
import classes from './Menu.module.css';
import { gsap, Linear,  } from 'gsap';

const Menu = (props) => {

  const menu = useRef();
  const innerCircle = useRef()
  const menuTextRef = useRef()

  //initialize timelines
  const t1 = gsap.timeline();
  const t2 = gsap.timeline();

  //animation when mouse is over the circle
  const onEnter = () => {
    menuTextRef.current.innerText = 'CLICK'
    t2.clear()
    t1.to(menu.current, {scale: 0.7, duration: 0.5, ease: "circ"})
    .to(innerCircle.current, {rotation:"+=360", transformOrigin:"50% 50%", ease:Linear.easeNone, repeat:-1, duration: 3})
  }

  //animation when mouse is out of the circle
  const onLeave = () => {
    menuTextRef.current.innerText = 'MENU'
    t1.clear()
    let tempRotation = innerCircle.current.style.transform.substring(innerCircle.current.style.transform.indexOf('rotate(')+7, innerCircle.current.style.transform.indexOf('deg'))
    t2.to(menu.current, {scale: 1, duration: 0.5, ease: "circ"})
    .to(innerCircle.current, {rotation: "-=" + tempRotation, duration: 3, ease:Linear.easeNone})
  }

  return(
    <div className={classes.container} onMouseOver={onEnter} onMouseLeave={onLeave} onClick={onLeave}>
      {/* Outer Circle */}
      <span className={classes.container}>
        <span className={classes.menuText} style={props.dark ? {color:'#000'} : {color:"#fff"}} ref={menuTextRef}>MENU</span>
        <span className={classes.outerCircle} ref={menu} style={props.dark ?{border:'black 1px solid' } : {border:'#d3d3d3 1px solid'}}></span>
      </span>

      {/* Inner Circle */}
      <span className={classes.innerContainer}  ref={innerCircle} >
        <span className={classes.circle} style={props.dark ? {backgroundColor:'#000'} : {backgroundColor:'#fff'}}></span>
        <span className={classes.innerCircle} style={props.dark ? {borderColor:'#000'} : {borderColor:"#fff"}}></span>
      </span>
      {props.close && <span className={classes.close}></span>}
    </div>
  )
}

export default Menu;