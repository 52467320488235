import React , { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './blogSlick.css'

let scroll1 ={
    dots: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode:true
}



const BlogMobile = (props) =>{
    const { slug } = useParams();
    const[service,setService] = useState([]);
    useEffect(  () => {
       (async () => {
        const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=3&_embed');
        const data = await response.json();
        const blogServices = data.map((datas) => {
        let servicethumbnail = datas?.['acf']?.['thumbnail']?.['url'];
        let datat = new Date(datas.date).toLocaleDateString('en-GB', {  day:"numeric", month:"long"});
        if(!servicethumbnail){
          servicethumbnail = 'https://1up.al/static/media/1upLogo.09249cdd.png';
        }
        return {
          id: datas.id,
          description: datas.title.rendered,
          date: datat,
          src: servicethumbnail,
          slug: datas.slug
        }
      });
      setService(blogServices);
     })();
    },[]);
    console.log(service);
    let blogposts = [];
    blogposts = service.filter(e => e.slug !== slug ); 
    console.log(blogposts)
    
    return(
        <div className='slideshowContainerMobile'>
        <Slider {...scroll1}>
          {blogposts.map((el) => { 
            return (
              <div key = {el.id} className='imgContainerMobile' >
                <a href ={`/blog/${el.slug}`} >         
                  <img src={el.src} className='slideshowImgMobile' style={{width:260, height:196, marginRight:13, marginLeft:15}} alt='blog img'></img>
                  {/* <div className='imgTextMobile'>
                    <p style={{fontSize:20, fontFamily:'Montserrat,sans-serif', color:'#FFFFFF', textAlign:'left'}}>{el.description} </p>
                    <span style={{fontSize:14, fontFamily:'SofiaPro-Medium',opacity:'0.45', textAlign:'left'}}>{el.date}</span>    
                  </div>       */}
                </a> 
              </div>
              )
            }
          )}  
        </Slider>
      </div>
    )
       
}

export default BlogMobile;