import React from 'react';
import { useRef, useEffect, useState } from 'react';

import classes from './CrazyProject.module.css'


const ThirdPage =()=>{

    
  
    return(
        <>
            <div className={classes.thirdcontainer}>
                <p className={classes.title} style={{fontFamily:"SofiaPro-Light"}}>DRIVE SAFE</p>
                <div className={classes.minithirdcontainer}>
                    <div className={classes.photo_3}>
                        <img src={require('../../assets/porsche/crazy/07@2x.png')} style={{width:"320px"}}></img>
                    </div>

                    {/* <div className={classes.thirdLine}></div> */}

                    <div className={classes.desc_3} style={{fontFamily:"SofiaPro-Light"}}>
                        <p className={classes.bigText} style={{fontFamily:"SofiaPro-Bold"}}>Self-Custody Crypto Wallet for DFT, NFT and Metaverse</p>
                        <p>Explore the list of 1000s Apps or connect Web3 App with a WalletConnect protocol</p>
                    </div>
                </div>
             
            </div>
        </> 
    )
 
}

export default ThirdPage;