import React ,{useEffect, useState} from "react";

import classes from './ServicesPage.module.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../components/slider.css';
const ServicesPage =()=>{

    const settings = {
        infinite: true,
		dots: false,
		arrows: false,
		slidesToShow: 3,
        centerMode: true,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  centerMode: true,
                  slidesToScroll: 1,
                  infinite: true
                }
              },
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				centerMode: true,
				slidesToScroll: 1,
				infinite: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: false
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: false
			  }
			}
		]
	};
    const [showResults, setShowResults] = useState(true)
    
    const [imageClicked, setImageClicked] = useState({
        first: true,
        second: false,
        third: false
      });
    
      const onClickHandler = (order) => {
        const resetImages = {
          first: false,
          second: false,
          third: false
        }
        setImageClicked({
          ...resetImages,
          [order]: true
        });
      };
      useEffect(() => {
        function reveal() {
            var reveals = document.querySelectorAll(".reveal");
          
            for (var i = 0; i < reveals.length; i++) {
              var windowHeight = window.innerHeight;
              var elementTop = reveals[i].getBoundingClientRect().top;
              var elementVisible = 150;
          
              if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
              } else {
                reveals[i].classList.remove("active");
              }
            }
          }
          
          window.addEventListener("scroll", reveal);
      },[]);
      
    return(
        <>
            <div className={classes.servicescont}>
                <div className={classes.desc} style={{fontFamily:"SofiaPro-Light"}}>
                    <div className="ServiceContainer reveal fade-left">
                        <Slider {...settings}>
                        <div onClick={() => onClickHandler("first")} className={classes.bigText}>
                            <img src={require('../../assets/porsche/crazy/07.png')} className={classes.imazh}/>
                            <div>EZ</div>
                        </div>
                        <div onClick={() => onClickHandler("second")} className={classes.bigText}>
                            <img src={require('../../assets/porsche/crazy/07.png')} className={classes.imazh}/>
                            <div>EZ</div>
                        </div>
                        <div onClick={() => onClickHandler("third")} className={classes.bigText}>
                            <img src={require('../../assets/porsche/crazy/07.png')} className={classes.imazh}/>
                            <div className={classes.smalldesc}>Self-Custody Crypto Wallet for DFT, NFT and Metaverse</div>
                        </div>
                        <div onClick={() => onClickHandler("second")} className={classes.bigText}>
                            <img src={require('../../assets/porsche/crazy/07.png')} className={classes.imazh}/>
                            <div >Self-Custody Crypto Wallet for DFT, NFT and Metaverse</div>
                        </div>
                      
                        </Slider>
                        
                    </div>
                </div>
                {imageClicked.first && <div className={classes.photo}>
            <img src={require('../../assets/porsche/crazy/07@2x.png')} style={{width:"320px"}}></img>
                 </div>}
                {imageClicked.third &&<div className={classes.photo}>
            <img src={require('../../assets/porsche/crazy/06@2x.png')} style={{width:"320px"}}></img>
                </div>}
                {imageClicked.second &&<div className={classes.photo}>
            <img src={require('../../assets/porsche/crazy/05@2x.png')} style={{width:"320px"}}></img>
                </div>}
            </div>
        </> 
    )
 
}

export default ServicesPage;