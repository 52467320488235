/* eslint-disable import/no-anonymous-default-export */
import { SET_SIDE } from './action';

const sideFromLocalStorage = sessionStorage.getItem('side');

let sideFromWeb;
if(sideFromLocalStorage !== 0){
  let url = window.location.href;
  if(url.includes('creative')){
    sideFromWeb = 1;
  }
  else{sideFromWeb = 0;}
}

const initialState = {
  side: sideFromWeb
}

 
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDE:
      sessionStorage.setItem('side', action.side);
      return {
        side: action.side,
      };
    default:
      return state;
  }
}