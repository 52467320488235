import React ,{lazy, Suspense } from 'react'
import classes from './Portfolio.module.css'

import GalleryProjects from '../../components/Projects/GalleryProjects'
import MediaQuery from 'react-responsive'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';

const PortfolioSerious = props => {
  const settings = {
    arrows: false,
    dots : false,
     slidesToScroll: 2,
     slidesToShow: 2,
     
 };
 const GalleryProjects = lazy(() => import('../../components/Projects/GalleryProjects'));
 const Slider = lazy(() => import('react-slick'));
 const renderLoader = () => <p>Loading...</p>;
    return (
        <>
          <Helmet>
      <title>Portfolio Serious</title>  
        <meta name="description" content="Portfolio Serious" />  
      </Helmet>
      <Suspense fallback={renderLoader()}>
        <GalleryProjects />
        </Suspense>
        <MediaQuery minWidth={800}>
        <div className={classes.partners}>
        <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/cocacola.png")} alt='cocacola' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/euronews.png")} alt='euronews' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/juth.png")} alt='juth' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/leklipharma.png")} alt='leklipharma' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/veko.png")} alt='veko' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/neptun.png")} alt='neptun' objectFit='contain' />
              </div>
            
              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/vodafone.png")} alt='vodafone' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/akep.png")} alt='akep' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/alphabank.png")} alt='alphabank' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/porsche.png")} alt='porsche' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/redbull.png")} alt='datacentrum' objectFit='contain' />
              </div>

              <div className={classes.partnerContainer}>
                <img src={require("../../assets/about/Partners/datacentrum.png")} alt='datacentrum' objectFit='contain' />
              </div>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={800}>
        
        <div className={classes.partners}>
        <Suspense fallback={renderLoader()}>
        <Slider {...settings}>
        <div>
                <img src={require("../../assets/about/Partners/cocacola.png")} alt='cocacola' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/euronews.png")} alt='euronews' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/juth.png")} alt='juth' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/leklipharma.png")} alt='leklipharma' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/veko.png")} alt='veko' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/neptun.png")} alt='neptun' objectFit='contain' />
              </div>
            
              <div>
                <img src={require("../../assets/about/Partners/vodafone.png")} alt='vodafone' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/akep.png")} alt='akep' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/alphabank.png")} alt='alphabank' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/porsche.png")} alt='porsche' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/redbull.png")} alt='datacentrum' objectFit='contain' />
              </div>

              <div>
                <img src={require("../../assets/about/Partners/datacentrum.png")} alt='datacentrum' objectFit='contain' />
              </div>
              </Slider>
              </Suspense>
        </div>
        
        </MediaQuery>
        </>
    )
}

export default PortfolioSerious