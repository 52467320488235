import React, { useRef } from 'react'
import ReactPageScroller from 'react-page-scroller';
import Horizontal from './HorizontalScroll';
import LastPage from './LastPage';
import ThirdPage from './ThirdPage';
import ForthPage from './ForthPage';
import FifthPage from './FifthPage';
import ServicesPage from './ServicesPage';
import SixthPage from './SixthPage';
import IndexPage from './Horizontal';



const CrazyProject = () => {
const ok = useRef();
document.onscroll = () => {
  // adjust for the difference between window height and width
  let percentScrolled = window.pageYOffset / window.innerHeight;
  let toScroll = percentScrolled * window.innerWidth;
  // scroll horizontally
  document.getElementById("scroll").style.bottom = "-" + toScroll  +  "px" ;
}
   return(
    <>
      <ReactPageScroller>
      <Horizontal/>
      <ThirdPage/>
      </ReactPageScroller>
      <IndexPage/>
    <div style={{height : '100vh',marginTop: '-1937px'}}>
      <div id='scroll' ref={ok} style={{position: 'relative',width: '100%',height: '100%'}}>
        <LastPage/>      
        <ServicesPage/>
        <FifthPage/>
      </div>
    </div>
    </>
   
   )

}  

export default CrazyProject;