import React ,{ useEffect, useState, lazy , Suspense} from 'react'
import { useNavigate} from "react-router-dom";

import Meta from '../../components/Meta';

import Footer from '../../components/Footer'

import classes from './AboutSerious.module.css'

import ImageSlider from '../../components/MultipleSlide'
import { Helmet } from 'react-helmet';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import '../../components/slider.module.css';

const AboutSerious = props => {
  
  const[logo,setLogo] = useState([]);
  useEffect(  () => {
     (async () => {
      const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=17&_embed&per_page=50');
      const data = await response.json();
      const partnersLogo = data.map((datas) => {
      let partnersImg = datas?.['acf']?.["SeriousLogo"]?.['sizes']?.['medium'];

      console.log(partnersImg)

      return {
       logo : partnersImg,
      }
    });
    setLogo(partnersLogo);
  })();
  },[]);
  console.log(logo);


  const dataMeta = [
      { 
        "id": "1",
        "title": "About Page",
        "description": "About Page Description",
        "keywords": "Uttar Pradesh",
        "image": "myimage",
        "type": "Page",
        "url": "1up.al/about"
      }
  ]

  const navigate = useNavigate()

  const PartnerLogo = lazy(() => import('./PartnerLogo'));
  const renderLoader = () => <p>Loading</p>;

  return (
    <>
     <Helmet>
      <title>About Serious</title>  
        <meta name="description" content="About Serious" />  
      </Helmet>
    {dataMeta.map((meta) => (
      <Meta key = {meta.id} title = {meta.title} description = {meta.description} keywords = {meta.keywords} image = {meta.image} type = {meta.type} url = {meta.url}  />
    ))}
    <div className={classes.aboutPage}>
      <div className={classes.blackContainer}>
        <div className="container">
          <div className={classes.contentBlock}>
            <h4>We are 1UP Labs, <br/>We innovate the way brands are build</h4>
            <p>Robust end to end solutions</p>
            <p>Resilient software architecture</p>
            <p>Creating digital experiences</p>
          </div>
        </div>
      </div>
      <Suspense fallback={renderLoader()}>
        <PartnerLogo/>
      </Suspense>
      <div className={classes.thirdContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.leftTextContainer}>
            <p>Shaping your digital future
              based on our beliefs
              </p>
          </div>
          <img src={require("../../assets/about/about_background_1.png")} alt='background' className={classes.leftBackgroundImage} />
        </div>

        <div className={classes.rightContainer}>
          <div className={classes.content}>

            <div className={classes.serviceContainer}>
              <div className={classes.serviceleft}>
                <div className={classes.serviceTextContainer}>
                  <span>CLIENTS</span>
                  <p>Create different and better solutions for the digital</p>
                </div>
                <div className={classes.serviceTextContainer}>
                  <span>PEOPLE</span>
                  <p>A high performance culture, with richer experience</p>
                </div>
                <div className={classes.serviceTextContainer}>
                  <span>NETWORK BRANDS</span>
                  <p>Empowering with global systems & platforms to </p>
                </div>
              </div>
              <div className={classes.serviceright}>
                <div className={classes.serviceTextContainer}>
                  <span>SOCIETY</span>
                  <p>A digital economy that works for everyone</p>
                </div>
                <div className={classes.serviceTextContainer}>
                  <span>TOGETHER</span>
                  <p>A team that’s empowered, diverse and inclusive</p>
                </div>
                <div className={classes.serviceTextContainer}>
                  <span>EFFICIENCY</span>
                  <p>We are customer-obsessed</p>
                </div>
              </div>
            </div>

            <img src={require("../../assets/about/Path1.png")} alt='background' className={classes.rightBackgroundImage} />

          </div>
        </div>
      </div>

      <div className={classes.bcklightGray}>
        <div className="container">
          <div className={classes.fourthContainer}>
            <div>
              <div className={classes.awardsContainer}>
                <div className={classes.conentGroup}>
                  <img src={require("../../assets/about/logo_1.png")} alt='oh__img4.1-min' className={classes.awardIcon_1} />
                  <div className={classes.conentText}>
                    <p className={classes.awardText}>Balcannes</p>
                    <p>One of the 25 best agency projects of the SEE region in 2017, Vodafone City</p>
                  </div>
                </div>
                <div className={classes.conentGroup1}>
                  <img src={require("../../assets/about/logo_2.png")} alt='oh__img4.1-min' className={classes.awardIcon_2} />
                  <div className={classes.conentText}>
                    <p className={classes.awardText}>WoW Actions</p>
                    <p>One of the three most WOW actions among all other OPCOs</p>
                  </div>
                </div>
              </div> 
            </div>
            <div className={classes.awardLeftTextContainer}>
              <p className={classes.subtitle}>AWARDS & HONORS</p>
              <p className={classes.title}>The awards won by our project.</p>
            </div>
            <p className={classes.desc}>Our clients describe us as a product team which creates amazing UI/UX experiences, 
                by crafting top-notch user experience.
            </p>
          </div>
        </div>
      </div>

      <div className={classes.team}>
        <img src={require("../../assets/about/Union1.png")} alt='tub' className={classes.teamBgr} />
        <h2>Meet Our Team</h2>
        <ImageSlider />
        <div className={classes.join}>
          <a href="mailto:info@1up.al">
            Join the team
            <img
              src={require('../../assets/icons/right-arrow-white.png')}
              alt='right-arrow'
              className={classes.arrowIcon}
            />
          </a>
          <p>we are hiring</p>
        </div>
        <img src={require("../../assets/about/tubFull.png")} alt='tubFull' className={classes.teamfullBgr} />
      </div>
      <div className='container'>
        <div className={classes.structureContainer}>
          <div className={classes.contactTextContainer}>
            <h4>LET’S TALK</h4>
            <h5>Got a project?</h5>
            <p>
              We’re a team of creatives who are excited about unique ideas and help digital and fin-tech companies to create amazing identity by crafting top-notch UI/UX.
            </p>
          </div>
       
         
            <button
              className={classes.contactButton}
              onClick={() => navigate('/contact')}
            >
              CONTACT US
              <img
                src={require('../../assets/icons/right-arrow-white.png')}
                alt='right-arrow'
                className={classes.contactButtontIcon}
              />
            </button>
      
        </div>
        <Footer />
      </div>

    </div>
    </>
  )
}

export default AboutSerious
