import { fitBounds } from "google-map-react";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './masonery.css'

const Masonery = () =>{
    
    const navigate = useNavigate()
    const [portfolio, setPortfolio] = useState([]);
    
    useEffect( () => {
        (async () => {
        const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=2&_embed&per_page=20');
        const data = await response.json();
        const portfoliodata = data.map((datas) => {
           let portfolioimg  =   datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.['source_url'];
           let portfolioimgwidth  =   datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['width'];
           let portfolioimgheight  =   datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['height'];
           let prtags =   datas?.['_embedded']?.['wp:term']?.[1].map((el) =>{return el.name});
            
      
          let imageSize = '';
          let score = '';
          const square=[];

          let ratio = (portfolioimgwidth / portfolioimgheight);
          if (ratio > 0.9 && ratio < 1.1) {
            imageSize = 'square';
            score = 75
          } else if (ratio < 0.85 && ratio > 0.6) {
            imageSize = 'rectangle';
            score = 25
          } else if (ratio <= 0.6) {
            imageSize = 'tallrectangle';
            score = 85
          } else if (ratio > 1.1 && ratio <= 1.7) {
            imageSize = 'smallrectangle';
            score = 15
          } else {
            imageSize = 'undefined';
            score = 0;
          }
          // console.log('fotot',portfolioimg)
          // for (let i=0; i < portfolioimg.length; i++){
          //   if(portfolioimg[i].portfolioimgwidth/portfolioimgheight > 0.9 && portfolioimgwidth/portfolioimgheight < 1.1){
          //     square.push(portfolioimg[i])
          //   }
          // }
          // console.log('katror',square)
          // const Square=({result})=>{
          //   const squareimg =[];

          // }

          return {
            src         : portfolioimg,
            tags        : prtags,
            slug        : datas.slug,
            ratio       : ratio,
            imageSize   : imageSize,
            portfolioimgwidth   : portfolioimgwidth,
            portfolioimgheight  : portfolioimgheight,
            score       : score,
            square      : square
          }

        });

        setPortfolio(portfoliodata);
    })();
    },[]);
    

      console.log("portofolio" ,portfolio);

    

    //   const resultsRender = []; 
 
    //         if(portfolio.length > 4)
    //         {
    //             for (let i = 0; i < portfolio.length; i += 2) {
    //                 resultsRender.push (
    //                     <div >
    //                         {
    //                         <>
    //                             {portfolio.slice(i, i + 2).map((items)=>(
    //                                     <img className={items.imageSize} src={items.src}/>
    //                                 ))}
    //                         </>                             
    //                         }
    //                     </div>
    //                 );
    //             }
    //         } 
        
    return(

        <div className="masonery">
            {/* {resultsRender} */}
            {portfolio.map((items)=>(
                <a href='#'>
                    <img className={items.imageSize} src={items.src}/>
                </a>
            ))}
        </div>
    )
}

export default Masonery;


