import React from "react";
import './Footeri.css';

const Footeri = () =>{
    return (
        <div className='terms'>
        <a className='text' href="/">
          Privacy & Cookie Policy
        </a>
        <span className= 'texti'>|</span>
        <a className='text' href="/">
          Terms of Service
        </a>
      </div>
    )
}

export default Footeri;