import React, { useState, useEffect} from 'react'
import classes from './Blog.module.css'
import Logo1 from './Logo1'
import Logo2 from './Logo2'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

const Blog = props => {
  const[article,setArticle] = useState([]);
  useEffect( () => {
    (async () => {
    const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=3&_embed');
    const data = await response.json();
    const blogArticles = data.map((datas) => {
      let blogthumbnail = datas?.['acf']?.['thumbnail']?.['url'];
      let blogthumbnail1  =  datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['large']?.['source_url'];
      
      if(!blogthumbnail){
        blogthumbnail = 'https://1up.al/static/media/1upLogo.09249cdd.png';
      }
      return {
        id: datas.id,
        description: datas.title.rendered,
        date: datas.date,
        src: blogthumbnail,
        src1 : blogthumbnail1,
        slug: datas.slug
      }
    });
    setArticle(blogArticles);
  })();
  },[]);
  
  const rows =  article.map((el) => 
  <Link className={classes.links} key = {el.id} to ={{pathname: `/blog/${el.slug}`, state : el.slug}}>
    <MediaQuery minWidth={769}>
    <Logo1  key={el.id} description = {el.description} date = {el.date} foto = {el.src} slug = {el.slug}/>
    </MediaQuery>
    <MediaQuery maxWidth={769}>
    <Logo1  key={el.id} description = {el.description} date = {el.date} foto = {el.src1} slug = {el.slug}/>
      </MediaQuery>
  </Link>);
  return (
    <div className={classes.row}>
       <Helmet>
      <title>Blog</title>  
        <meta name="description" content="Blog" />  
      </Helmet>
      <div className={classes.itemblog}>
        <Logo2 />    
        {article && rows}  
      </div>  
    </div>
  )
}

export default Blog;