import React, {useState,useEffect, lazy, Suspense, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import ImagesPos from './ImagesPos'
import classes from './PortfolioCrazy.module.css'
import { Helmet } from 'react-helmet';
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import MediaQuery from 'react-responsive'
import Draggable from 'gsap/Draggable'
import {gsap} from 'gsap'
const PortfolioCrazy = () => {
  let flyingSaucer = useRef();
  const projectImages = [
    { id: 1, path: require('../../assets/home/img1.png') },
    { id: 2, path: require('../../assets/home/img2.png') },
    { id: 3, path: require('../../assets/home/img3.png') },
    { id: 4, path: require('../../assets/home/img4.png') },
    { id: 5, path: require('../../assets/home/img5.png') },
    { id: 6, path: require('../../assets/home/img6.png') },
    { id: 7, path: require('../../assets/home/img7.png') },
    { id: 8, path: require('../../assets/home/img8.png') },
    { id: 9, path: require('../../assets/home/img9.png') },
  ]
  const [portfolioCrazy, setPortfolioCrazy] = useState([]);
  const navigate = useNavigate();
  useEffect( () => {
      (async () => {
      const response = await fetch('https://cms.1up.al/wp-json/wp/v2/posts?categories=2&_embed');
      const data = await response.json();
      const portfoliodata = data.map((datas) => {
         let portfolioimg  =  datas?.['_embedded']?.['wp:featuredmedia']?.[0]?.['media_details']?.['sizes']?.['full']?.['source_url'];
         let prtags =   datas?.['_embedded']?.['wp:term']?.[1].map((el) =>{return el.name});

        if(!portfolioimg){
          portfolioimg = '';
        }
        return {
          path: portfolioimg,
          tags: prtags,
          slug : datas.slug
        }
        
      });
      setPortfolioCrazy(portfoliodata);

      if (typeof window !== "undefined") {
        gsap.registerPlugin(Draggable);
      }
  
      Draggable.create(flyingSaucer.current, {
        type:"x",
        bounds: {width: 1000, top: 100},
        inertia:false
      });
    })();
    },[]);
    


    console.log(portfolioCrazy);
    const ImagesPos = lazy(() => import('./ImagesPos'));
    const renderLoader = () => <p>Loading...</p>;
    
  return (
    
    <motion.div
      initial={{ y: '100vh' }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ y: '100vh' }}
      className={classes.containermob}
    >
       <Helmet>
      <title>Portfolio Crazy</title>  
        <meta name="description" content="Portfolio Crazy" />  
      </Helmet>
      <MediaQuery minWidth={768}>
       <MouseParallaxContainer 
            containerStyles={{
              overflow:'hidden',
              display: "block",
            }}
            resetOnLeave globalFactorX = {0.5}
            >
  <MouseParallaxChild  factorX={1.5}
              factorY={0.6}>
      <div className={classes.container}>
       {portfolioCrazy.map((el,index) =><> 
          <Suspense fallback={renderLoader()}>
        <div className={classes.crazyproject} onClick={()=>{navigate("#")}}>
        <ImagesPos top = {
          index === 0 ? '1.41%' : 
          index === 1 ? '-20%' : 
          index === 2 ? '31.48%' : 
          index === 3 ? '72.22%' : 
          index === 4 ? '74.07%' : 
          index === 5 ? '36.11%' : 
          index === 6 ? '77.78%' : 
          index === 7 ? '46.3%' : 
          index === 8 ? '46.11%' : '10%'} 
          left = {
          index === 0 ? '9.29%' : 
          index === 1 ? '62.5%' : 
          index === 2 ? '12.79%' :
          index === 3 ? '14.58%' : 
          index === 4 ? '38.54%' : 
          index === 5 ? '79.92%' : 
          index === 6 ? '75%' : 
          index === 7 ? '94.7%' : 
          index === 8 ? '-4%' : '-30.7%'} path = {index === 0 ? el.path : index = index + 1 ? el.path : ''}></ImagesPos>
           </div>
        </Suspense>
        </>)} 
         <div>
          <Link to="#" className={classes.description} >
             Our mission is to lead in the creation
            and development of the most advanced technology systems and deliver more value
            to your business.
          </Link>
        </div>
      </div>
    </MouseParallaxChild></MouseParallaxContainer>
    </MediaQuery>
    <MediaQuery maxWidth={768}>
    <div className={classes.container} ref={flyingSaucer}>
       {portfolioCrazy.map((el,index) =><> 
          <Suspense fallback={renderLoader()}>
        <div className={classes.crazyproject} onClick={()=>{navigate("#")}}>
        <ImagesPos top = {
          index === 0 ? '20.41%' : 
          index === 1 ? '20%' : 
          index === 2 ? '25.48%' : 
          index === 3 ? '79.22%' : 
          index === 4 ? '74.07%' : 
          index === 5 ? '25.11%' : 
          index === 6 ? '77.78%' : 
          index === 7 ? '72.3%' : 
          index === 8 ? '71.11%' : '20%'} 
          left = {
          index === 0 ? '47.29%' : 
          index === 1 ? '121.5%' : 
          index === 2 ? '80.79%' :
          index === 3 ? '48.58%' : 
          index === 4 ? '94.54%' : 
          index === 5 ? '165.92%' : 
          index === 6 ? '131%' : 
          index === 7 ? '170.7%' : 
          index === 8 ? '11%' : '1.7%'} path = {index === 0 ? el.path : index = index + 1 ? el.path : ''}></ImagesPos>
           </div>
        </Suspense>
        </>)} 
        
         </div>
         <div className={classes.descmob}>Our mission is to lead in the creation
            and development of the most advanced technology systems and deliver more value
            to your business.</div>
    </MediaQuery>
    </motion.div>
    
  )

}



export default PortfolioCrazy