import React from 'react';
import classes from './Logo1.module.css';
import MediaQuery from 'react-responsive'

  // Import result is the URL of your image
// return <div className={classes.Logo1}><img blurRadius= {1} src={img_vod_biz}></img> </div>

const Logo1 = (props) => {
  let mystyle = {
    backgroundImage : `url(${props.foto})`
  };
  <MediaQuery maxWidth={769}>
  
  </MediaQuery>
  let datat = new Date(props.date).toLocaleDateString('en-GB', {  day:"numeric", month:"long"});
  
    return ( 
    <div className={classes.itemblog} >
        <div  className={classes.ome}><span className={classes.covertext}>{props.description}</span> <span className={classes.datat}>{datat}</span></div>
        
         
         {/* <img className={classes.inner} src={img_vod_biz}></img> */}
         <MediaQuery minWidth={769}>
         <div  className={classes.inner} style = {mystyle}>
       
         </div>
         </MediaQuery>
          <MediaQuery maxWidth={769}>
           <div  className={classes.inner} style = {{backgroundImage : `linear-gradient(90deg, #0000005e 10%, transparent),url(${props.foto})`}}>
       
           </div>
          </MediaQuery>
        
    </div>)
};


export default Logo1;
