import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
//import { AnimatePresence } from 'framer-motion'

import { StrictMode } from "react";

//import screens
import HomeCrazy from "./screens/HomeCrazy";
import HomeSerious from "./screens/HomeSerious";
import AboutCrazy from "./screens/AboutCrazy";
import AboutSerious from "./screens/AboutSerious";
//import PortfolioCrazy from './screens/PortfolioCrazy'
import PortfolioSerious from "./screens/PortfolioSerious";
import PortfolioCrazyProject from "./screens/PortfolioCrazyProject";
import PortfolioSeriousProject from "./screens/PortfolioSeriousProject";
import Blog from "./screens/Blog";
import BlogItem from "./screens/BlogItem/BlogItem";
import ContactCrazy from "./screens/ContactCrazy";
import ContactSerious from "./screens/ContactSerious";
import Choose from "./screens/ChooseScreen";
import Porsche from "./screens/PortfolioSeriousProject/Porsche/Porsche";
import Masonery from "./screens/PortfolioSerious/Masonery";
import GeneralInfo from "./components/GeneralInfo";
import CrazyProject from "./screens/PortfolioCrazyProject/CrazyProject";

//import components
import Header from "./components/Header";
import { HelmetProvider } from "react-helmet-async";
//page not found
import Error from "./screens/Error/Error";

import "./App.css";
import PortfolioCrazy from "./screens/PortfolioCrazy/PortfolioCrazy";
import CocaCola from "./components/Cocacola";
import { JoinOurTeam } from "./screens/ContactSerious/JoinOurTeam";

const App = () => {
  let location = useLocation();

  const side = useSelector((state) => state.side);
  console.log("side:" + side);
  return (
    <HelmetProvider>
      <StrictMode>
        {location.pathname != "/portfolio/project/creative/" &&
          location.pathname != "/cocacola/privacy" && <Header />}

        {/* <Header /> */}
        <Routes>
          <Route path="/" exact element={<Choose />} />
          {/* <Route path='/home/creative' element ={<HomeCrazy/>}/> */}
          <Route
            path={side ? "/home/creative" : "/home"}
            element={side ? <HomeCrazy /> : <HomeSerious />}
          />
          <Route
            path={side ? "/about/creative" : "/about"}
            element={side ? <AboutCrazy /> : <AboutSerious />}
          />
          <Route
            path={side ? "/portfolio/creative" : "/portfolio"}
            element={side ? <PortfolioCrazy /> : <PortfolioSerious />}
          />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogItem />} />
          <Route
            path={side ? "/contact/creative" : "/contact"}
            element={side ? <ContactCrazy /> : <ContactSerious />}
          />
          <Route
            path="/portfolio/project/creative/:id"
            element={<PortfolioCrazyProject />}
          />
          <Route
            path="/portfolio/project/creative/"
            element={<CrazyProject />}
          />
          <Route path="/portfolio/project/:slug" element={<Porsche />} />
          <Route path="*" element={<Error />} status={404} />
          <Route path="/portofolio/project/:slug" element={<Porsche />} />
          <Route path="/masonery" element={<Masonery />} />
          <Route path="/:slug" element={<GeneralInfo />} />
          <Route path="/cocacola/privacy" element={<CocaCola />} />
          <Route path="/join-us" element={<JoinOurTeam></JoinOurTeam>} />
        </Routes>
      </StrictMode>
    </HelmetProvider>
  );
};

export default App;
