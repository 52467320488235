import React, { useEffect, useState } from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../screens/AboutSerious/slider.css';
import MediaQuery from 'react-responsive';



import classes from './MultipleSlide.module.css'


const ImageSlider = ({images}) => {
	
	const settings = {
		infinite: true,
		dots: false,
		arrows: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		speed: 10000,
		autoplaySpeed: 0,
		cssEase: 'linear',
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			}
		]
	};

	const settingsAttr = {
		infinite: true,
		dots: false,
		arrows: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		speed: 10000,
		autoplaySpeed: 0,
		cssEase: 'linear',
		rtl: true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				centerMode: true,
				slidesToScroll: 1,
				infinite: true
			  }
			}
		]
	};

	const [users_row1, setUsers_row1] = useState([])
	const [users_row2, setUsers_row2] = useState([])
	const [user_mobile, setUser_mobile] = useState([])

	const fetchData = () => {
		fetch("https://cms.1up.al/wp-json/wp/v2/posts?categories=9&_embed&per_page=50")
		.then(response => {
			return response.json()
		})
		.then(data => {
			const pageSize = data.length;
			const page = 2;

			setUsers_row1(data.slice(0, pageSize/page));
			setUsers_row2(data.slice(pageSize/page, pageSize));
			setUser_mobile(data)
			console.log(data)
		})
	}




	useEffect(() => {
		fetchData()
		
	}, [])

	

	

	return (
		<>
		<MediaQuery minWidth={900}>
			<div>
				<Slider {...settings} className='aboutSlider'>

					{users_row2.sort((a, b)=>a.date>b.date ?1 :-1).map(users1 => (
					<div key={users1.id}>
						<div  className={classes.teamSingle}>
							<img src={users1._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} alt={users1.title} />
							<div>
								<h6>{users1.title.rendered}</h6>
								<div dangerouslySetInnerHTML={{ __html: users1.excerpt.rendered }} />
							</div>
						</div>
					</div>
				))}
				</Slider>
			</div>
			<div>
				<Slider {...settingsAttr} className='aboutSlider'>
					{users_row1.sort((a, b)=>a.date>b.date ?1 :-1).map(users_row2 => (
						<div key={users_row2.id}>
							<div  className={classes.teamSingle}>
								<img src={users_row2._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} alt={users_row2.title} />
								<div>
									<h6>{users_row2.title.rendered}</h6>
									<div dangerouslySetInnerHTML={{ __html: users_row2.excerpt.rendered }} />
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
		</MediaQuery>
			
		<MediaQuery maxWidth={900}>
			<div>
			<Slider {...settings} className='aboutSlider'>

				{user_mobile.sort((a, b)=>a.date>b.date ?1 :-1).map(user_mobile => (
				<div key={user_mobile.id}>
					<div  className={classes.teamSingle}>
						<img src={user_mobile._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} alt={user_mobile.title} />
						<div>
							<h6>{user_mobile.title.rendered}</h6>
							<div dangerouslySetInnerHTML={{ __html: user_mobile.excerpt.rendered }} />
						</div>
					</div>
				</div>
			))}
			</Slider>
			</div>
		
		</MediaQuery>
			
		</>
	)
}
export default ImageSlider;
