import React from "react";
import classes from './Footer.module.css';
import logo from '../assets/icons/iconLogo.svg';

const Footer=()=> {



  return (
    <footer className={classes.footer} >
      <div className={classes.footer_container}>
        <div>
          <h3 className={classes.h3}>
             © {new Date().getFullYear()}, 1UP Labs
          </h3>
        </div>
        <div>
          <img
            src={logo}
            className={classes.logo}
            alt='Logo'
          />
        </div>
        <div className={classes.terms}>
          <a className={classes.text} href="/privacy-policy" target="_blank" >
            Privacy Policy
          </a>
          <span>|</span>
          <a className={classes.text} href="/cookie-policy/" target="_blank" >
            Cookie Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;